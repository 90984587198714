import React, { Component } from 'react';
import OrgChart from '@balkangraph/orgchart.js';
import { Button } from '@mantine/core';
type MyProps = OrgChart.options;
//
export interface IOrcOrgTree extends OrgChart.options {
    // FieldName: string;
    // ClassName?: string;
    // ParentFieldName: string;
    // ViewName : string;
    // ViewModel? : OrcViewResult;
    // Data? : any;
    // TableSaveClicked?: (data: any) => void;
    //SaveClicked?: (buttonName: string) => void;
    // ShowMessage?: (message: string) => void;
    // SetDisplayMode?: () => void;
  }



//
export default class Chart extends Component<MyProps> {
    private divRef: React.RefObject<HTMLInputElement>;
    chart: OrgChart | undefined;

    constructor(props: {}) {
        super(props);
        this.divRef = React.createRef();
    }

    shouldComponentUpdate() {
        return false;
    }

    componentDidMount() {
        if (this.divRef.current != null) {
            OrgChart.templates.TemplateO = Object.assign({}, OrgChart.templates.ana);
            OrgChart.templates.TemplateO.Name = `<text class="field_0" style="font-size: 20px;" fill="#ffffff" x="10" y="30" text-anchor="left">{val}</text>`;
            OrgChart.templates.TemplateO.Location = `<text class="field_1" style="font-size: 14px;" fill="#ffffff" x="10" y="50" text-anchor="left">{val}</text>`;
            OrgChart.templates.TemplateO.CostCenter = `<text class="field_2" style="font-size: 12px;" fill="#ffffff" x="10" y="70" text-anchor="left">{val}</text>`;
            OrgChart.templates.TemplateO.node =
                '<rect filter="url(#isla-shadow1)" x="0" y="0" rx="5" ry="5" height="120" width="250" fill="#081b59" stroke-width="1" stroke="#1EC9E8" ></rect> ';
            OrgChart.templates.TemplateO.editFormHeaderColor= '#081b59';



            OrgChart.templates.TemplateS = Object.assign({}, OrgChart.templates.ana);
            OrgChart.templates.TemplateS.Ename = `<text class="field_1" style="font-size: 20px;" fill="#ffffff" x="125" y="30" text-anchor="middle">{val}</text>`;
            OrgChart.templates.TemplateS.Title = `<text class="field_0" style="font-size: 14px;" fill="#ffffff" x="125" y="50" text-anchor="middle">{val}</text>`;

            //OrgChart.templates.TemplateS.Pernr = `<text class="field_2" style="font-size: 12px;" fill="#ffffff" x="125" y="70" text-anchor="middle">{val}</text>`;
            OrgChart.templates.TemplateS.node =
                '<rect filter="url(#isla-shadow1)" x="0" y="0" rx="5" ry="5" height="120" width="250" fill="#106929" stroke-width="1" stroke="#1EC9E8" ></rect> ';
                OrgChart.templates.TemplateS.editFormHeaderColor= '#106929';
            // OrgChart.templates.ana.field_3 =
            //     `<text class="field_3" style="font-size: 14px;" fill="#ffffff" x="125" y="90" text-anchor="middle">{val}</text>`;
            // how to create your own template

            this.chart = new OrgChart(this.divRef.current, {
                layout: this.props.layout,
                 nodes: this.props.nodes,
                //nodes: [],
                template: "TemplateO",
                editForm: {
                    readOnly: false,
                    generateElementsFromFields: false
                    // elements: [
                    //     { type: 'textbox', label: 'Name', binding: 'Name' },
                    //     { type: 'textbox', label: 'Location', binding: 'Location' },
                    //     { type: 'textbox', label: 'Cost Center', binding: 'CostCenter' },
                    //     { type: 'textbox', label: 'Full Name', binding: 'Ename' },
                    //     { type: 'textbox', label: 'Title', binding: 'Title' }        
                    // ]
                },
                tags: {
                    "Department": {
                        template: "TemplateO",
                        nodeMenu: {
                            addDep: {
                                //icon: webcallMeIcon,
                                text: "Add Department",
                                onClick: this.addDepartment
                            },
                            addPos: {
                                //icon: webcallMeIcon,
                                text: "Add Position",
                                onClick: this.addPosition
                            }
                        }
                    },
                    "Position": {
                        template: "TemplateS",
                        nodeMenu: {
                            addPos: {
                                // icon: webcallMeIcon,
                                text: "Add Position",
                                onClick: this.addPosition
                            }
                        }
                    }
                    
                },
                nodeBinding: { 
                    Name: "Name",
                    Location: "Location",
                    CostCenter: "CostCenter",
                    Title: "Title",
                    Ename: "Ename"
                },
                // nodeContextMenu: {
                //     edit: { text: "Edit Department", icon: OrgChart.icon.edit(14, 14, '#039BE5') },
                //     add: { text: "Add New Department", icon: OrgChart.icon.add(14, 14, '#FF8304') },
                //     remove: { text: "Remove Department", icon: OrgChart.icon.remove(14, 14, '#FF8304') }
                // },
                nodeMenu: {
                    // details: { text: "Details", icon: OrgChart.icon.details(18, 18, '#039BE5') },
                    add: { text: "Add" },
                    edit: { text: "Edit" },
                    remove: { text: "Remove" },
                    addDep: {
                        //icon: webcallMeIcon,
                        text: "Add Department",
                        onClick: this.addDepartment
                    }
  
                },
                menu: {
                    pdf: { text: "Export PDF" },
                    png: { text: "Export PNG" },
                    svg: { text: "Export SVG" },
                    csv: { text: "Export CSV" },
                    save: { text: "Save", onClick: this.Save }
                }
                // editUI: {
                //     show: function (sender, id) {
                //         config.editForm.buttons.map = null;
                //     },
                //     hide: function () {
                //         //alert("hide");
                //     },
                //     init: function (sender) {
                //     }


                // }

                
            });

            var myChart = this.chart;
            //
            this.chart.editUI.on('show', function(sender, id){
                debugger;
                var data :any = myChart.get(id);
                if(data.Otype == "O"){
                    myChart.config.editForm != undefined ?  myChart.config.editForm.elements= [
                        { type: 'textbox', label: 'Name', binding: 'Name' },
                        { type: 'textbox', label: 'Location', binding: 'Location' },
                        { type: 'textbox', label: 'Cost Center', binding: 'CostCenter' }   
                    ] : myChart.config.editForm = undefined;
                    debugger;
                }   
                else if(data.Otype == "S"){
                    myChart.config.editForm != undefined ?  myChart.config.editForm.elements= [
                        { type: 'textbox', label: 'Ename', binding: 'Ename' },
                        { type: 'textbox', label: 'Title', binding: 'Title' }
                    ] :  myChart.config.editForm = undefined;
                    debugger;
                }
            });
            //
            // this.chart.editUI.on('button-click', function(sender, id){
            //     debugger;
            //     var data :any = myChart.get(id);

            // });


            this.chart.on('init', function () {
               //autoFlip();
            //    chart.add({ id: 1, field_0: "Denny Curtis", field_1: "CEO" });
            //    this.chart.add({ id: 2, pid: 1, field_0: "Ashley Barnett", field_1: "Sales Manager" });
            //    this.chart.add({ id: 3, pid: 1, field_0: "Ashley Barnett2", field_1: "Sales Manager" });
            //    this.chart.add({ id: 4, pid: 1, field_0: "Ashley Barnett3", field_1: "Sales Manager" });
            //    this.chart.draw();
            });

            // var nodes = [
            //     { id: 1, name: "Denny Curtis", title: "CEO", img: "https://cdn.balkan.app/shared/2.jpg", desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus interdum purus id fringilla tempor. Etiam pellentesque accumsan enim, vel consectetur est viverra nec.' },
            //     { id: 2, pid: 1, name: "Ashley Barnett", title: "Director", img: "https://cdn.balkan.app/shared/3.jpg", desc: 'Quisque vulputate libero risus, eget tincidunt dui gravida tristique. In nec tincidunt risus. Etiam malesuada neque a ipsum dictum, et sodales dolor dapibus.' },
            //     { id: 3, pid: 1, name: "Caden Ellison", title: "Sales Director", img: "https://cdn.balkan.app/shared/4.jpg", desc: 'Morbi sodales aliquam mi, malesuada vehicula felis egestas et. Quisque ornare pharetra felis.' },
            //     { id: 4, pid: 2, name: "Elliot Patel", title: "Marketing Manager", img: "https://cdn.balkan.app/shared/5.jpg", desc: 'Vitae egestas lacus laoreet at. Sed gravida tincidunt pretium. Fusce non luctus velit, quis feugiat lacus. Donec non varius enim. Sed sed tempus lorem. ' },
            //     { id: 5, pid: 2, name: "Lynn Hussain", title: "Dev Manager", img: "https://cdn.balkan.app/shared/6.jpg", desc: 'Phasellus congue maximus dictum. Integer accumsan felis sit amet felis dignissim sagittis. Sed neque nibh, eleifend quis pellentesque quis, maximus ac ligula. ' },
            //     { id: 6, pid: 3, name: "Tanner May", title: "Dev Team Lead", img: "https://cdn.balkan.app/shared/7.jpg", desc: 'Nunc metus risus, accumsan dictum sodales id, egestas vitae ligula. Vestibulum non magna ac quam cursus porta. Mauris eget augue cursus, aliquam metus sed, convallis ipsum.' }
            // ];

            // var nodes2 = [
            //     { id: 1, Department: "HRSP", Location: "İstanbul", CostCenter: "1000" },
            //     { id: 2, stpid:1, Department: "Orchestra", Location: "İstanbul", CostCenter: "2000" },
            //     { id: 3, pid: 2, Department: "Orchestra", Location: "İstanbul", CostCenter: "2000" }

            // ];

            // var nodes2 =[
            //     {id: 0}, 
            //     {id: 1, pid: 0}, 
            //     {id: 2, stpid: 1}, 
            //     {id: 3, pid: 2},
            //     {id: 4, pid: 0}, 
            //     {id: 5, stpid: 4}
            // ] ;

            
            // var nodes2 =[
            //     {id: 0,  Department: "HRSP"}, 
            //     {id: 1, pid: 0, Department: "Orchestra"},
            //     {id: 2, stpid: 1, Department: "Bekir Karadeniz"},
            //     {id: 3, pid: 2, Department: "Recep Kanbir"},
            //     {id: 4, pid: 3, Department: "Mehmet Emin Ersöz"},
            //     {id: 5, pid: 0, Department: "SAP"},
            //     {id: 6, stpid: 5, Department: "Serhat Demir"},
            //     {id: 7, pid: 6, Department: "Zeki"},
            //     {id: 8, pid: 6, Department: "Merve"},
            //     {id: 9, pid: 6, Department: "Selen"},
            //     {id: 10, pid: 6, Department: "Koral"},
            //     {id: 11, pid: 6, Department: "Hüseyin"},
            // ] ;

            
            // this.chart.load(nodes2);




            this.chart.on('add', function (sender, node) {
                //this.chart.add({ id: 1, field_0: "Denny Curtis", field_1: "CEO" });
                console.log('added', node);
            });



        }
        
    }

    Save = () => {

        alert("save22");

        console.log("nodes to be saved" , this.chart?.nodes);

        let node =  this.chart?.getNode("1");

        console.log("node" , node);

        let node2 =  this.chart?.get("1");

        console.log("node2" , node2);

    }

    addDepartment = (nodeId : string) => {
        let nodeData = this.chart?.get(nodeId);
         this.chart?.addNode( { id: this.chart?.generateId(), pid: nodeData?.id, Name: "New Department", Location:"??", CostCenter: "??", Otype: "O", Objid: "", tags: ["Department"]},);
       // this.chart?.draw();
        console.log("nodeData add dep" , nodeData);
        // let employeeName = nodeData["name"];
        // window.open('https://webcall.me/' + employeeName, employeeName, 'width=340px, height=670px, top=50px, left=50px');
    }

    addPosition = (nodeId : string) => {
        let nodeData = this.chart?.get(nodeId);
         this.chart?.addNode( { id: this.chart?.generateId(), pid: nodeData?.id, Title: "New Position", Ename:"New Person", Otype: "S", Objid: "", tags: ["Position"]},);
       // this.chart?.draw();
        console.log("nodeData add dep" , nodeData);
        // let employeeName = nodeData["name"];
        // window.open('https://webcall.me/' + employeeName, employeeName, 'width=340px, height=670px, top=50px, left=50px');
    }



    render() {
        return (
            <div id="tree" ref={this.divRef}></div>
        );
    }
}

// function EditFormDisplay() {

// alert("save22");

// }