import * as React from "react"

import { cn } from "@/lib/utils"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select"
import { Icon } from "@iconify/react"
import { useContext } from "react"
import { OrcContext } from "@/context/OrcContext"


interface AccountSwitcherProps {
  isCollapsed: boolean
  // accounts: {
  //   label: string
  //   email: string
  //   icon: React.ReactNode
  // }[]
}

class AccountType {
  label: string = "";
  email: string = "";
  icon: React.ReactNode
}

export function AccountSwitcher({
  isCollapsed,
  // accounts,
}: AccountSwitcherProps) {
  //

  //
  const currentContext = useContext(OrcContext);

  debugger;
  
  var accounts :AccountType[] = [
    {
      label: currentContext.User ? currentContext.User?.Ename : "Select an account",
      email: currentContext.User ? currentContext.User?.UserName : "x@x.com",
      icon:  <Icon icon="mingcute:user-4-line" width="19" height="19" />
    },
    // {
    //   label: "Seren Karadeniz",
    //   email: "serenk@hrsp.com.tr",
    //   icon:  <Icon icon="mingcute:user-4-line" width="19" height="19" />
    // },
    // {
    //   label: "Serhat Demir",
    //   email: "serhatd@hrsp.com.tr",
    //   icon:  <Icon icon="mingcute:user-4-line" width="19" height="19" />
    // }
  ];

  //isCollapsed = true; //this should come from external
  // var emptyAccount = {
  //   label: "Select an account",
  //   email: "",
  //   icon: <Icon icon="bi:person-circle" width="19" height="19" />
  // };

  const [selectedAccount, setSelectedAccount] = React.useState<string>(
    accounts[0].email
  )

  return (
    <Select defaultValue={selectedAccount} onValueChange={setSelectedAccount}>
      <SelectTrigger
        className={cn(
          "flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0",
          isCollapsed &&
            "flex h-9 w-9 shrink-0 items-center justify-center p-0 [&>span]:w-auto [&>svg]:hidden"
        )}
        aria-label="Select account"
      >
        <SelectValue placeholder="Select an account">
          {accounts.find((account) => account.email === selectedAccount)?.icon}
          <span className={cn("ml-2", isCollapsed && "hidden")}>
            {
              accounts.find((account) => account.email === selectedAccount)
                ?.label
            }
          </span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {accounts.map((account) => (
          <SelectItem key={account.email} value={account.email}>
            <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
              {account.icon}
              {account.email}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}