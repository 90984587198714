import clsx from "clsx";

export interface IOrcRoundedImage {
  FieldName: string;
  ClassName?: string;
  AltText?: string;
  Src: string;
  ParentFieldName?: string;
}

export default function OrcRoundedImage(props: IOrcRoundedImage) {
  return (
    <>
        <img 
          key={props.FieldName}
          src={props.Src ? props.Src : "https://randomuser.me/api/portraits/women/76.jpg"} 
          alt={props.AltText} 
          className={clsx("rounded-full", props.ClassName)} />
    </>
  );
}
