import DIContainer from "@/models/DIContainer";
import { DataElement } from "@/models/datadictionary/DataElement";
import IResponseHelper, { ResponseIsNameOk } from "@/models/response/IResponseHelper";

class DataElementService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: DataElement) => {
    const response = await this.httpClient.post<IResponseHelper<DataElement>>(
      "/api/v1/dd/dataelement",
      data
    );

    return response;
  };

  activate = async (data: DataElement) => {
    const response = await this.httpClient.post<IResponseHelper<DataElement>>(
      "/api/v1/dd/dataelement/activate",
      data
    );

    return response;
  };

  put = async (data: DataElement) => {
    const response = await this.httpClient.put<IResponseHelper<DataElement>>(
      "/api/v1/dd/dataelement",
      data
    );

    return response;
  };

  isExist = async (rollname: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/dd/dataelement/isexist?rollname=" + rollname
    );

    return response.data;
  };

  IsNameOk = async (rollname: string) => {
    const response = await this.httpClient.get<IResponseHelper<ResponseIsNameOk>>(
      "/api/v1/dd/dataelement/IsNameOk?Name=" + rollname
    );

    return response.data;
  };

  getByName = async (rollname: string) => {
    const response = await this.httpClient.get<IResponseHelper<DataElement>>(
      "/api/v1/dd/dataelement/getByName?rollname=" + rollname
    );

    return response.data;
  };

  delete = async (rollname: string) => {
    const response = await this.httpClient.delete<IResponseHelper<Boolean>>(
      "/api/v1/dd/dataelement/delete?rollname=" + rollname
    );

    return response.data;
  };

  copy = async (copyobjname: string, objname: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/dd/dataelement/copy?copyobjname=" + copyobjname + "&objname=" + objname
    );

    return response.data;
  };
}

export default new DataElementService();
