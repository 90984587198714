import React, { createContext, useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNav from "@/components/SideNav";
import MarginWidthWrapper from "@/components/margin-width-wrapper";
import PageWrapper from "@/components/page-wrapper";
import HeaderMobile from "@/components/header-mobile";
import UserHeader from "@/components/UserHeader";
import ThemeProvider from "@/context/ThemeContext";
import { OrcContext, OrcContextProvider } from "@/context/OrcContext";
import { Icon } from "@iconify/react";
import OrcRobot from "@/pages/protected/comps/OrcRobot";
export default function ProtectedLayout() {
  const location = useLocation();
  const { pathname } = location;
  const currentContext = useContext(OrcContext);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (currentContext.User == null) navigate("/login");
  // }, []);
  const [hasBgImage, setHasBgImage] = useState(true);
  // const [bg, setBgImage] = useState(
  //   "https://images.unsplash.com/photo-1712921674379-a5c66302ddf2?q=80&w=1973&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  // );

  const [bg, setBgImage] = useState("/images/bg/bg001.jpg");

  //sconsole.log(pathname);
  // if (pathname.startsWith("/apps/tiles/")) setHasBgImage(true);
  // else setHasBgImage(false);

  useEffect(() => {
    if (pathname.startsWith("/apps/tiles/")) setHasBgImage(true);
    else setHasBgImage(false);
  }, []);

  return (
    <div
      //className="fix"
      // style={{
      //   backgroundImage: hasBgImage ? `url(${bg})` : "none",
      // }}
    >
      {/* <UserHeader /> */}
      <div className="flex flex-row h-auto">
        <SideNav />
        <HeaderMobile />

        <div
          className="w-full font-sapFont"
          // style={{
          //   backgroundImage: `url(https://images.unsplash.com/photo-1712921674379-a5c66302ddf2?q=80&w=1973&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
          // }}
        >
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeInOut", duration: 0.55 }}
            key={pathname}
          >
            <Outlet />

          </motion.div>
          {/* Robot */}
          {/* <OrcRobot /> */}
        </div>

        {/* <MarginWidthWrapper>
          <HeaderMobile />
          <PageWrapper>
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ ease: "easeInOut", duration: 0.55 }}
              key={pathname}
            >
              <Outlet />
            </motion.div>
          </PageWrapper>
        </MarginWidthWrapper> */}
      </div>
    </div>
  );
}
