import { TrendingDown, TrendingUp } from "lucide-react"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shadcn/ui/card"
import { ChartConfig, ChartContainer } from "@/shadcn/ui/chart"
import { PropsWithChildren, useEffect, useState } from "react"
import useHttpClient from "@/hook/useHttpClient";
import IResponseHelper from "@/models/response/IResponseHelper";
import { Icon } from "@iconify/react"
import clsx from "clsx"
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuTrigger } from "@/shadcn/ui/dropdown-menu";
import Item from "@/models/screen/Item";
import { useNavigate } from "react-router-dom";
import { Button } from "@/shadcn/ui/button";
import { Toggle } from "@/shadcn/ui/toggle";
import { Switch } from "@/shadcn/ui/switch";
import { Label } from "@/shadcn/ui/label";
export const description = "A radial chart with a custom shape"



export interface IOrcCard {
  FieldName: string;
  ClassName?: string;
  ContentClassName?: string;
  ParentFieldName?: string;
  Title: string;
  SubTitle: string;
  Src?: string;
  NavItems : NavItem[];
  NavIcon?: string;
  ShowNav: boolean;
  ShowOkButton: boolean;
  
}

export class NavItem {
  Url: string = "";
  Text: string= "";
  IconName: string= "";
  NewTab: boolean = false
}




const OrcCard : React.FC<PropsWithChildren<IOrcCard>> = (props) => {

  const navigate = useNavigate();
  let cclassname = "";
  if(props.ContentClassName == null)
  {
    cclassname = "flex flex-col gap-3";
  }
  else
  {
    cclassname = props.ContentClassName
  }
  
  // const [loaded, setLoaded] = useState(false);
  // const { isLoading, request } = useHttpClient();

  // let inftyList: Item[] = [];

  // const [inftylist, setinftylist ] =  useState<Item[]>(inftyList);

  // useEffect(() => {
  //   fetch();
  // }, []);

  // const fetch = async () => {
  //   setLoaded(false);
  //   const resp = await request<IResponseHelper<Item[]>>(`/api/v1/program?ProgName=` + props.Progname, "GET");
  //   if(resp==null) return;
  //   if (resp.data) {
  //     setinftylist(resp.data);
  //     console.log(resp.data);
  //     setLoaded(true);
  //     //
  //   } else {
  //     setLoaded(true);

  //   }
  //   debugger;
  // };

  const GotoPage = (n: NavItem) => {
    if(n.NewTab)
      window.open(n.Url, '_blank');
    else
      navigate(n.Url);

  };

  return (
    <Card className={clsx("min-h-64", props.ClassName)}>
      <CardHeader className="items-center pb-0 mb-1">
        <CardTitle>{props.Title}</CardTitle>
        <CardDescription>{props.SubTitle}</CardDescription>
      </CardHeader>
      <CardContent className={clsx("", cclassname)}>
        {props.children}
      </CardContent>
      <CardFooter className="flex flex-row justify-between">
        <div className="">
          {props.ShowOkButton && 
            <div className="flex items-center space-x-2">
            <Switch id="itisok" />
            <Label htmlFor="itisok">Anladım</Label>
          </div>}
        </div>
        <div className="">
          {props.ShowNav == true && <DropdownMenu>
            <DropdownMenuTrigger asChild>
            <span className="font-bold hover:cursor-pointer">
              {props.NavIcon ? <Icon icon={props.NavIcon} /> : <Icon icon="eva:more-vertical-fill" />}

            </span>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56 h-56 overflow-y-auto">
              {props.NavItems !=null && props.NavItems.map((navitem) => 
                    <DropdownMenuItem onClick={() => GotoPage(navitem)}>
                    <span>{navitem.Text}</span>
                  </DropdownMenuItem>)}
            </DropdownMenuContent>
          </DropdownMenu>}
        </div>
      </CardFooter>
    </Card>
  )
}

export default OrcCard;
