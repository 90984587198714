import { FieldType, ScreenEvent } from "../Enums";
import { UIPageType } from "../uipage/UIPageModel";
import OrcBCItem from "./OrcBCItem";
import OrcField from "./OrcField";
import OrcFileItem from "./OrcFileItem";
import OrcMessageItem from "./OrcMessageItem";
import { OrcScreenParam } from "./OrcScreenParam";
import OrcStatus from "./OrcStatus";

export class OrcScreen {
  Mandt: string = "";
  UICode: string = "";
  UIPath?: string = "";
  HRef: string = "";
  UIPageType: UIPageType = UIPageType.Screen;
  HelpUrl?: string;
  ShowPageTitle: boolean = true;
  EnableLog: boolean = false;
  Title: string = "";
  SubTitle?: string;
  BGImage: string = ""; //new
  PageTitleClassName: string = ""; //new
  ShowPageHeader: boolean = true; //new
  ShowPageSubTitle: boolean = true; //new
  ShowHeaderSeperator: boolean = true; //new
  ShowBreadCrumb: boolean = true;
  PostFieldName?: string;
  PostSubFieldName?: string;
  RowKeyClicked?: string;
  RowData?: any;
  PageInfoIconsClassName?: string;
  TaskName?: string;
  LeaveScreen: boolean = true;
  // GetResult: boolean = true;
  //PostResult: boolean = true;
  // PostBackResult: boolean = true;
  // GetMessages: string[] = [];
  Messages: OrcMessageItem[] = [];
  // PostBackMessages: string[] = [];
  NextScreen?: string;
  ScreenEvent: ScreenEvent = ScreenEvent.Get;
  BGClassName?: string;
  TileClassName?: string;
  TileIconName?: string;
  TileIconClassName?: string;
  MenuCode?: string;
  RoleName?: string;
  ProgName?: string;
  Seqnr: number = 99;
  ModuleCode?: string = "";
  IsActive: boolean = true;
  IsMobile: boolean = true;
  ShowAsTile: boolean = true;
  UnAuthorized: boolean = true;

  Figure?: string;
  FigureClassName?: string;
  PageJSON?: string;

  CreatedBy?: string;
  CreatedOn?: string;
  ChangedBy?: string;
  ChangedOn?: string;

  Files: OrcFileItem[] = [];
  Fields: OrcField[] = [];
  Statuses: OrcStatus[] = [];
  BCLinks: OrcBCItem[] = [];
  Params: OrcScreenParam[] = [];
  Console: string[] = [];
}

// export class BaseField implements IBaseField {
//   FieldName: string = "";
//   ParentFieldName: string = "#";
//   ClassName: string = "";
//   Visible: boolean = true;
//   HasChildren: boolean = false;
//   FieldType: FieldType = FieldType.OrcCheckBox;
// }

// export interface IBaseField {
//   FieldName: string;
//   ParentFieldName: string;
//   ClassName: string;
//   Visible: boolean;
//   FieldType: FieldType;
// }
