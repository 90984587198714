import { TrendingUp } from "lucide-react"
import { Pie, PieChart } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shadcn/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/shadcn/ui/chart"
import { useEffect, useState } from "react"
import useHttpClient from "@/hook/useHttpClient"
import IResponseHelper from "@/models/response/IResponseHelper"

export const description = "A donut chart"

const chartData = [
  { browser: "chrome", visitors: 275, fill: "#7C0902" },
  { browser: "safari", visitors: 200, fill: "#9F8170" },
  { browser: "firefox", visitors: 187, fill: "#592720" },
  { browser: "edge", visitors: 173, fill: "#893F45" },
  { browser: "other", visitors: 90, fill: "#996515" },
]

const chartConfig = {
  DataValue: {
    label: "Visitors",
  },
  chrome: {
    label: "Chrome",
    color: "hsl(var(--chart-1))",
  },
  safari: {
    label: "Safari",
    color: "hsl(var(--chart-2))",
  },
  firefox: {
    label: "Firefox",
    color: "hsl(var(--chart-3))",
  },
  edge: {
    label: "Edge",
    color: "hsl(var(--chart-4))",
  },
  other: {
    label: "Other",
    color: "hsl(var(--chart-5))",
  },
} satisfies ChartConfig

export interface IOrcPieChart {
  Progname: string;
}
enum Trend {
  Up = 0,
  Down = 1,
}

class KPIPieChartModel {
  Title: string = "";
  SubTitle: string = "";
  ChartData: KPIPieChartModelDataItem[] = [];
  Units: string = "";
  Footer: string = "";
  ShowTrend: boolean = true;
  InnerRadius: number = 60;
  Trend: Trend = Trend.Up;
  TrendWord: string = "";
}

class KPIPieChartModelDataItem {
  DataKey: string = "";
  DataValue: number = 0;
  fill: string = "";

}

export function OrcPieChart(props: IOrcPieChart) {

  const [loaded, setLoaded] = useState(false);
  const { isLoading, request } = useHttpClient();

  let initialKPI: KPIPieChartModel = new KPIPieChartModel();

  const [KPIData, setKPIData ] =  useState<KPIPieChartModel>(initialKPI);

  useEffect(() => {
    if(props.Progname != null)
      fetch();
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await request<IResponseHelper<KPIPieChartModel>>(`/api/v1/program?ProgName=` + props.Progname, "GET");
    if(resp==null) return;
    if (resp.data) {
      setKPIData(resp.data);
      console.log(resp.data);
      setLoaded(true);
      //
    } else {
      setLoaded(true);

    }
    debugger;
  };
  
  return (
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square"
        >
          <PieChart className="">
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
             data={KPIData.ChartData}
              //data={chartData}
              dataKey="DataValue"
              nameKey="DataKey"
              // label
              // labelLine={false}
              innerRadius={KPIData.InnerRadius}
            />
          </PieChart>
        </ChartContainer>

  )
}
