import { Label } from "@/shadcn/ui/label";
import { useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Program } from "@/models/development/Program";
import ProgService from "@/services/dev/ProgService";
import Editor from "@monaco-editor/react";
import OrcIdeService from "@/services/ide/OrcIdeService";
import CodeCompletionRequest from "@/models/orcide/CodeCompletionRequest";
import SignatureHelpResult, {
  Parameter,
  Signatures,
} from "@/models/orcide/SignatureHelpResult";

import { Toaster } from "@/shadcn/ui/toaster";
import CodeCheckRequest from "@/models/orcide/CodeCheckRequest";
import { Icon } from "@iconify/react";
import { toast } from "@/shadcn/ui/use-toast";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shadcn/ui/dialog";
import { Button } from "@/shadcn/ui/button";

//
//
function DeleteProgramDetail() {
  //
  //
  const navigate = useNavigate();
  const routeParams = useParams();
  console.log("routeParams = ", JSON.stringify(routeParams));
  //const monaco1 = useMonaco();

  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/tiles/develop");
            }}
          >
            Developments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/se38");
            }}
          >
            Programs
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{routeParams.progname}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialPageObject: Program = {
    ProgName: routeParams.progname ? routeParams.progname : "",
    CodeLines: "",
    LastChanged: "HRSP",
    LastChangedOn: "20240101",
    Package: "ORC_HR",
    Definition: "",
  };

  const monacoRef = useRef(null);
  const editorRef = useRef(null);

  const [completionDisposable, setCompletionDisposable] = useState<any>();
  const [saved, setSaved] = useState(true);

  useEffect(() => {
    return () => {
      if (
        completionDisposable?.dispose &&
        typeof completionDisposable.dispose === "function"
      ) {
        completionDisposable.dispose();
      }
    };
  }, [completionDisposable]);

  useEffect(() => {
    SetLoaded(false);
    fetch();
    SetLoaded(true);
  }, []);

  const fetch = async () => {
    if (routeParams.progname) {
      const resp = await ProgService.getByName(routeParams.progname);
      if (resp) {
        if (resp) {
          SetPage(resp);
        } else {
          debugger;
        }
      }
    }
  };

  function OnMount(editor: any, monaco: any) {
    //editorRef.current = editor;
    editor.focus();
    // editor.getAction("editor.action.formatDocument").run();
    editorRef.current = editor;
  }

  function BeforeMount(monaco: any) {
    // here is the monaco instance
    // do something before editor is mounted
    monacoRef.current = monaco;

    // Code Completion Trigger
    setCompletionDisposable(
      monaco.languages.registerCompletionItemProvider("csharp", {
        triggerCharacters: [".", " "],
        provideCompletionItems: async (model: any, position: any) => {
          let suggestions: any = [];

          //https://github.com/dotnetprojects/MonacoRoslynCompletionProvider
          // suggestions.push({
          //   label: {
          //     label: "elem.Suggestion",
          //     description: "elem.Description",
          //   },
          //   kind: monaco.languages.CompletionItemKind.Function,
          //   insertText: "elem.Suggestion",
          // });
          // let request = {
          //   Code: model.getValue(),
          //   Position: model.getOffsetAt(position),
          //   Assemblies: "assemblies",
          // };

          debugger;
          let request: CodeCompletionRequest = {
            Code: model.getValue(),
            Position: model.getOffsetAt(position),
            Assemblies: [],
          };

          let response = await OrcIdeService.complete(request);
          if (response.data) {
            for (let elem of response.data) {
              suggestions.push({
                label: {
                  label: elem.Suggestion,
                  description: elem.Description,
                },
                kind: monaco.languages.CompletionItemKind.Function,
                insertText: elem.Suggestion,
              });
            }
          }

          return { suggestions: suggestions };
        },
      })
    );

    //start signature
    monaco.languages.registerSignatureHelpProvider("csharp", {
      signatureHelpTriggerCharacters: ["("],
      signatureHelpRetriggerCharacters: [","],

      provideSignatureHelp: async (
        model: any,
        position: any,
        token: any,
        context: any
      ) => {
        let request: CodeCompletionRequest = {
          Code: model.getValue(),
          Position: model.getOffsetAt(position),
          Assemblies: [],
        };

        let response = await OrcIdeService.signature(request);
        if (!response.data) return;

        let signatures: Signatures[] = [];
        for (let signature of response.data.Signatures) {
          let params: Parameter[] = [];
          for (let param of signature.Parameters) {
            params.push({
              Label: param.Label,
              Documentation: param.Documentation ?? "",
            });
          }

          signatures.push({
            Label: signature.Label,
            Documentation: signature.Documentation ?? "",
            Parameters: params,
          });
        }

        let signatureHelp: SignatureHelpResult = {
          Signatures: signatures,
          ActiveParameter: response.data.ActiveParameter,
          ActiveSignature: response.data.ActiveSignature,
        };

        return {
          value: signatureHelp,
          dispose: () => {},
        };
      },
    });
    //end signature

    //start hover
    monaco.languages.registerHoverProvider("csharp", {
      provideHover: async function (model: any, position: any) {
        let request: CodeCompletionRequest = {
          Code: model.getValue(),
          Position: model.getOffsetAt(position),
          Assemblies: [],
        };

        let response = await OrcIdeService.hover(request);

        if (response.data) {
          var posStart = model.getPositionAt(response.data.OffsetFrom);
          var posEnd = model.getPositionAt(response.data.OffsetTo);

          return {
            range: new monaco.Range(
              posStart.lineNumber,
              posStart.column,
              posEnd.lineNumber,
              posEnd.column
            ),
            contents: [{ value: response.data.Information }],
          };
        }

        return null;
      },
    });
    //end hover

    //code check
    monaco.editor.onDidCreateModel(function (model: any) {
      async function validate() {
        let request: CodeCheckRequest = {
          Code: model.getValue(),
          Assemblies: [],
        };

        let response = await OrcIdeService.codecheck(request);
        if (response.data) {
          let markers = [];

          for (let elem of response.data) {
            var posStart = model.getPositionAt(elem.OffsetFrom);
            var posEnd = model.getPositionAt(elem.OffsetTo);
            markers.push({
              severity: elem.Severity,
              startLineNumber: posStart.lineNumber,
              startColumn: posStart.column,
              endLineNumber: posEnd.lineNumber,
              endColumn: posEnd.column,
              message: elem.Message,
              code: elem.Id,
            });
          }

          monaco.editor.setModelMarkers(model, "csharp", markers);
        }
      }

      var handle: any = null;
      model.onDidChangeContent(() => {
        monaco.editor.setModelMarkers(model, "csharp", []);
        clearTimeout(handle);
        handle = setTimeout(() => validate(), 500);
      });
      validate();
    });

    //end code check
  }

  const [PageObject, SetPage] = useState(InitialPageObject);
  const [loaded, SetLoaded] = useState(false);

  const PropChanged = (fname: string, val: any) => {
    if (fname === "Definition") {
      let newobj: Program = { ...PageObject, Definition: val };
      setSaved(false);
      SetPage(newobj);
    } else if (fname === "Package") {
      let newobj: Program = { ...PageObject, Package: val };
      setSaved(false);
      SetPage(newobj);
    } else if (fname === "CodeLines") {
      let newobj: Program = { ...PageObject, CodeLines: val };
      setSaved(false);
      SetPage(newobj);
    }
  };

  function handleEditorChange(value: any, event: any) {
    let newobj: Program = { ...PageObject, CodeLines: value };
    setSaved(false);
    SetPage(newobj);
  }

  const Save = async () => {
    debugger;
    SetLoaded(false);
    const resp = await ProgService.put(PageObject);
    if (resp.data) {
      setSaved(true);
      //navigate("/apps/se38");
      toast({
        title: "Program saved",
        description: "Do not forget to activate",
      });
    } else {
      toast({
        title: "Program cannot be saved",
        description: resp.usermessage,
      });
    }
    SetLoaded(true);
  };

  const Compile = async () => {
    debugger;
    if (saved === false) {
      toast({
        title: "Save program first",
        description: "To activate, please save your changes first",
      });
      return;
    }
    SetLoaded(false);
    const resp = await ProgService.compile(PageObject);
    if (resp.data) {
      setSaved(true);
      //navigate("/apps/se38");
      toast({
        title: "Successfull:)",
        description: "Program has been activated",
      });
    } else {
      toast({
        title: "Program cannot be activated",
        description: resp.usermessage,
      });
    }
    SetLoaded(true);
  };

  const Delete = async () => {
    debugger;
    SetLoaded(false);
    const resp = await ProgService.delete(PageObject.ProgName);
    if (resp.data) {
      navigate("/apps/se38");
    } else {
      toast({
        title: "Program cannot be deleted",
        description: resp.usermessage,
      });
    }
    SetLoaded(true);
  };

  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-black">
        Change "{routeParams.progname}"
      </h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      {/* Commands Start */}
      <div className="flex flex-row flex-nowrap my-2 gap-2">




        <Dialog>
          <DialogTrigger asChild={true}>
            <button
              className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
              title="Delete Program"
            >
              <Icon
                icon="material-symbols-light:delete-outline"
                width="18"
                height="18"
                className="text-black"
              />
            </button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px] font-sapFont">
            <DialogHeader>
              <DialogTitle>Delete Program</DialogTitle>
              <DialogDescription>
                Please confirm you are sure to delete this program
              </DialogDescription>
            </DialogHeader>

            <div className="w-full flex items-center justify-center gap-1">
              <button
                className="w-full h-8 bg-red-100 flex justify-center items-center rounded-sm border-red-300 border-[1px] hover:bg-gray-300"
                onClick={() => Delete()}
              >
                Delete Program
              </button>
            </div>
          </DialogContent>
        </Dialog>

      </div>
      {/* Commands End */}
      <div className="w-full flex flex-col gap-2">
        <Editor
          height="90vh"
          className="rounded-lg bg-gray-50"
          
          theme="vs-dark"
          defaultLanguage="csharp"
          defaultValue="//start coding from here.."
          beforeMount={BeforeMount}
          onMount={OnMount}
          value={PageObject.CodeLines}
          // onChange={handleEditorChange}
          options={{
            autoIndent: "full",
            codeLens: false,
            readOnly: true,
            
            // fontFamily: "Consolas, 'Courier New', monospace",
          }}
        />
      </div>
      <Toaster />
      {/* {JSON.stringify(PageObject)} */}
    </div>
  );
}

export default DeleteProgramDetail;

//https://microsoft.github.io/monaco-editor/typedoc/interfaces/editor.IStandaloneEditorConstructionOptions.html#autoSurround
