import DIContainer from "@/models/DIContainer";
import { Structure } from "@/models/datadictionary/Structure";
import IResponseHelper, { ResponseIsNameOk } from "@/models/response/IResponseHelper";

class StructureService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: Structure) => {
    const response = await this.httpClient.post<IResponseHelper<Structure>>(
      "/api/v1/dd/structure",
      data
    );

    return response;
  };

  activate = async (data: Structure) => {
    const response = await this.httpClient.post<IResponseHelper<Structure>>(
      "/api/v1/dd/structure/activate",
      data
    );

    return response;
  };

  put = async (data: Structure) => {
    const response = await this.httpClient.put<IResponseHelper<Structure>>(
      "/api/v1/dd/structure",
      data
    );

    return response;
  };

  isExist = async (tabname: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/dd/structure/isexist?tabname=" + tabname
    );

    return response.data;
  };

  IsNameOk = async (tabname: string) => {
    const response = await this.httpClient.get<IResponseHelper<ResponseIsNameOk>>(
      "/api/v1/dd/structure/IsNameOk?Name=" + tabname
    );

    return response.data;
  };

  getByName = async (tabname: string) => {
    const response = await this.httpClient.get<IResponseHelper<Structure>>(
      "/api/v1/dd/structure/getByName?tabname=" + tabname
    );

    return response.data;
  };

  delete = async (tabname: string) => {
    const response = await this.httpClient.delete<IResponseHelper<Boolean>>(
      "/api/v1/dd/structure/delete?tabname=" + tabname
    );

    return response.data;
  };

  copy = async (copyobjname: string, objname: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/dd/structure/copy?copyobjname=" + copyobjname + "&objname=" + objname
    );

    return response.data;
  };

}

export default new StructureService();
