import { useTranslation } from "react-i18next";
import catalog from "../data/hrmodules.json";
import PageHeader from "./PageHeader";
import { Icon } from "@iconify/react";
import React from "react";
import { toast } from "@/shadcn/ui/use-toast";
import ClientExistCheckService from "@/services/clientexist/ClientExistCheckService";
import { Input } from "@/shadcn/ui/input";
import { Toaster } from "@/shadcn/ui/toaster";
import useHttpClient from "@/hook/useHttpClient";
import IResponseHelper from "@/models/response/IResponseHelper";
import clsx from "clsx";

function ClientLoginRedirectComp() {
  const { isLoading, request } = useHttpClient();
  const { t, i18n } = useTranslation("", { keyPrefix: "ClientLoginRedirectComp" });
  const[enabled, setEnabled] = React.useState(true);
  const[subdomain, setSubDomain] = React.useState("");

  const[hostname, setHostName] = React.useState(process.env.REACT_APP_HOSTNAME);

  const[scheme, setSchema] = React.useState("http://");
  

  const GoToLogin = async() => {
   
    if(subdomain == "") {

      toast({
        title: t("Warning1"),
        description: t("Warning2")
      });
      return;
    }

    //const hostName = process.env.REACT_APP_HOSTNAME;
    //const isExist = await ClientExistCheckService.get(subdomain);
   
    const resp = await request<IResponseHelper<boolean>>(`/api/v1/clientcheck?subdomain=${subdomain}`, "GET");
    if(resp==null)
      return;

    if(resp && resp.data){
      if(process.env.NODE_ENV === "development")
        window.location.href = `http://${subdomain}.${hostname}/login`;
      else
        window.location.href = `https://${subdomain}.${hostname}/login`;
    }
    else
    {
      toast({
        title: t("Subdomain") + " " + subdomain + " " + t("NotExist")
      });

    }
      //alert(subdomain + " is not exist");


  }

  return (
    <section className="w-full mt-24 mb-6 flex justify-center items-center">
    <div className="w-full md:w-96 mx-4 h-64 bg-white flex flex-col items-center gap-2">
      {/* SubDomain Entry Area */}
      <div className="flex flex-row gap-1">
        <div className="mt-2 text-gray-400">https://</div>
        <Input className="w-40 lowercase" type="text" id="subdomain" placeholder="" onChange={(e) => { setSubDomain(e.target.value.toLowerCase())}}  />
        <div className="mt-2 text-gray-400">.{ hostname }</div>
      </div>
    {/* Button to  go */}
    {/* <div className="w-full"> */}
      <button className={clsx("w-full  hover:bg-gray-500 text-white py-2 px-4 rounded", isLoading ? "bg-gray-500" : "bg-gray-800")} disabled={isLoading} onClick={GoToLogin}>
       {isLoading ? "Checking..." : t("Continue")}
      </button>
    {/* </div> */}
    
    </div>
    <Toaster />
</section>
  );
}

export default ClientLoginRedirectComp;
