import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import { HeaderMenuItem } from "@/models/navi/HeaderMenuItem";
import { HEADER_ITEMS_HR, HEADER_ITEMS_PLUS } from "@/constants";
function Header() {
  const { t, i18n } = useTranslation("", { keyPrefix: "Landing_Menu" });
  const [loaded, setLoaded] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  let navigation2: HeaderMenuItem[] = [];
  const [navigation, setNavigation] = useState(navigation2);
  debugger;
  //const currentLangu = i18n.language || window.localStorage.i18nextLng;
  console.log(navigation);

  useEffect(() => {
    setLoaded(false);
    setNavigation(HEADER_ITEMS_HR);
    //console.log("Domain found as ", DomainHelper.Domain);
    // if (DomainHelper.Domain === CurrentDomain.orchestraplus)
    //   setNavigation(HEADER_ITEMS_PLUS);
    // else setNavigation(HEADER_ITEMS_HR);
    setLoaded(true);
  }, []);

  const ChangeLangu = () => {
    if (i18n.language == "en") {
      i18n.changeLanguage("tr");
    } else {
      i18n.changeLanguage("en");
    }
  };

  // if (process.env.NODE_ENV == "development") {
  //   navigation.push({ name: t("Clients"), href: "/clients", classnames: "" });
  //   navigation.push({
  //     name: t("Login"),
  //     href: "/login",
  //     classnames: "",
  //   });
  // }

  return (
    <>
      {loaded && (
        <header className="px-10 py-5">
          <nav
            className="hidden md:flex items-center justify-between"
            aria-label="Global"
          >
            <div key="top_left" className="flex flex-row gap-8">
              <Link to="/" className="">
                <span className="h-12 md:h-12 w-auto rounded-full hover:scale-110 transform transition duration-110 font-bold">

                    <img
                      src="/logob.png"

                      alt="OrchestraHCM"
                      className="h-16 w-16 rounded-lg"
                    />


                </span>
              </Link>
              <Link to="/product" className="tracking-tight py-4 font-sapFont hover:text-gray-600 hover:cursor-pointer">{t("Product")}</Link>
              <Link to="/customers" className="tracking-tight py-4 font-sapFont hover:text-gray-600 hover:cursor-pointer">{t("Customers")}</Link>
              <Link to="https://orchestrahr-com.gitbook.io/docs" target="_blank" className="tracking-tight py-4 font-sapFont hover:text-gray-600 hover:cursor-pointer">Guides</Link>

              
            </div>
            <div key="top_right"  className="flex flex-row gap-2">
              <Link to="/company/go" className="tracking-tight border-2 w-36 border-green-300 bg-green-800 text-white flex items-center justify-center h-12 px-5 rounded-full hover:bg-green-600 text-md font-medium transition duration-200">{t("CompanyLogin")}</Link>
            </div>
          </nav>
        </header>
      )}
    </>
  );
}

export default Header;
