import React, { Children, PropsWithChildren } from "react";
import OrgChart from './MyTree';
import OrgChartNS from '@balkangraph/orgchart.js';
type MyProps = OrgChartNS.options;
///https://balkan.app/OrgChartJS/Docs/Fields
////https://code.balkan.app/org-chart-js/first-look#JS

interface IOrcOrg {
    FieldName: string;
    ClassName?: string;
    ParentFieldName?: string;
    Scenerio: string;
    Layout?: string;
    RootOtype?: string;
    RootObjid?: string;
    Plvar?: string;
    Com:string;
    Disabled:boolean;
  }

const OrcOrg: React.FC<PropsWithChildren<IOrcOrg>> = (props) => {

    var _layout = props.Layout ==null ? undefined : OrgChartNS.layout.normal;

    switch (props.Layout) {
        case "normal":
            _layout = OrgChartNS.layout.normal;
            break;
        case "mixed":
            _layout = OrgChartNS.layout.mixed;
            break;
        case "tree":
            _layout = OrgChartNS.layout.tree;
            break;
        case "treeLeftOffset":
            _layout = OrgChartNS.layout.treeLeftOffset;
            break;
        case "treeRightOffset":
            _layout = OrgChartNS.layout.treeRightOffset;
            break;
        case "treeLeft":
            _layout = OrgChartNS.layout.treeLeft;
            break;
        case "treeRight":
            _layout = OrgChartNS.layout.treeRight;
            break;
        case "grid":
            _layout = OrgChartNS.layout.grid;
            break;
        default:
            _layout = OrgChartNS.normal;
    }

    var _nodes : any = [];

    
    
    _nodes =[
                {id: 0,  Name: "HRSP", Location:"İstanbul", CostCenter: "1000", Otype: "O", Objid: "1", tags: ["Department"]}, 
                {id: 1, pid: 0, Name: "Orchestra", Location:"İstanbul", CostCenter: "2000", Otype: "O", Objid: "2", tags: ["Department"]},
                {id: 2, pid: 1, Ename: "Bekir Karadeniz", Title: "Product Manager", Otype: "S", Objid: "3",  tags: ["Position"], img: "https://cdn.balkan.app/shared/2.jpg"},
                {id: 3, pid: 2, Ename: "Recep Kanbir", Title: "Orchestra Builder Team Lead",  Otype: "S", Objid: "4", tags: ["Position"]},
                {id: 4, pid: 3, Ename: "Mehmet Emin Ersöz", Title: "Orchestra Builder",  Otype: "S", Objid: "5",tags: ["Position"]},
                {id: 5, pid: 0, Name: "SAP", Location:"İstanbul", CostCenter: "3000", Otype: "O", Objid: "6",tags: ["Department"]},
                {id: 6, pid: 5, Ename: "Serhat Demir", Title: "SAP Manager",   Otype: "S", Objid: "7",tags: ["Position"]},
                {id: 7, pid: 6, Ename: "Zeki",  Otype: "S", Objid: "8",tags: ["Position"]},
                {id: 8, pid: 6, Ename: "Merve",  Otype: "S", Objid: "9",tags: ["Position"]},
                {id: 9, pid: 6, Ename: "Selen",  Otype: "S", Objid: "10",tags: ["Position"]},
                {id: 10, pid: 6, Ename: "Koral",  Otype: "S", Objid: "11",tags: ["Position"]},
                {id: 11, pid: 6, Ename: "Hüseyin",  Otype: "S", Objid: "12",tags: ["Position"]},
            ] ;

    //_nodes =[];



      return (
          <div style={{height: '100%', width:'1200px'}}>

              <OrgChart  
                key={props.FieldName} 
                layout={_layout}           
                nodes={_nodes}
              
              
              />
          </div>
      );

}

export default OrcOrg;

