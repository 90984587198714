import clsx from "clsx";
import { Label } from "@/shadcn/ui/label";
import { Constants } from "@/models/Consts";

export interface IOrcFile {
  FieldName: string;
  ClassName?: string;
  CaptionClassName?: string;
  Disabled?: boolean;
  Required?: boolean;
  HasError?: boolean;
  Caption?: string;
  OnChange?: (_file: File | null) => void; // OnChange şimdi File nesnesini dönecek
}

export default function OrcFile(props: IOrcFile) {
  return (
    <div className={clsx("flex flex-col gap-1 w-full")}>
      {props.Caption ? (
        <Label
          htmlFor={props.FieldName}
          className={clsx(
            "font-normal",
            props.CaptionClassName ? props.CaptionClassName : ""
          )}
        >
          {props.Caption + (props.Required ? " *" : "")}
        </Label>
      ) : null}
      <div>
        <input
          key={props.FieldName}
          id={props.FieldName}
          type="file"
          onChange={(e) => {
            const file = e.target.files?.[0] || null; // İlk dosyayı al, yoksa null
            if (props.OnChange) props.OnChange(file);
          }}
          className={clsx(
            "rounded-md p-2 text-left border-gray-100 border",
            props.ClassName,
            props.HasError
              ? "border-red-200 bg-red-50 border-2"
              : "border-gray-300 border",
            props.Disabled ? Constants.InActive : ""
          )}
          disabled={props.Disabled}
        />
      </div>
    </div>
  );
}
