import { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from  "react-datepicker";
import { tr } from 'date-fns/locale/tr';
import clsx from "clsx";
import { Label } from "@/shadcn/ui/label";
import { Constants } from "@/models/Consts";

//https://refine.dev/blog/react-date-picker/#conditionally-disable-dates
//https://reactdatepicker.com/
//https://www.npmjs.com/package/date-fns
//https://date-fns.org/v3.6.0/docs/lightFormat
export interface IOrcDatePicker {
  FieldName: string;
  ClassName?: string;
  CaptionClassName?: string;
  Data: string;
  ParentFieldName: string;
  Disabled? : boolean;
  Required? : boolean;
  HasError?: boolean;
  Caption?: string;
  ScreenName?: string;
  RollName?: string;
  DomName?: string;
  DataType?: string;
  Length?: string;
  OnChange?: (_value: any) => void;
  HelpRequested?: (e:any) => void;
}
//

export default function OrcDatePicker(props: IOrcDatePicker) {
  //
  registerLocale('tr', tr);

  //
  

  function convertYYYYMMDDToDate(dateStr: string): Date | null {

    if (!dateStr) return null;
    if (dateStr.length !== 8 || isNaN(Number(dateStr))) {
      console.error("Invalid date format. Expected YYYYMMDD.");
      return null;
    }
  
    const year = parseInt(dateStr.substring(0, 4), 10);
    const month = parseInt(dateStr.substring(4, 6), 10) - 1; // Month is 0-indexed
    const day = parseInt(dateStr.substring(6, 8), 10);
  
    return new Date(year, month, day);
  }

  function convertDateToYYYYMMDD(date: Date | null): string | null {
    //
    if(date == null) return null;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed, add 1
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}${month}${day}`;
  }
  //
 // const [startDate, setStartDate] = useState<Date | null>(convertYYYYMMDDToDate(props.Data));
  return (
    <div className="flex flex-col gap-1" >
     {props.Caption ? <Label className={clsx("font-normal", props.CaptionClassName ? props.CaptionClassName : "")}>{props.Caption + (props.Required ? " *" : "")}</Label>: null} 
       <div>
          <DatePicker 
            key={props.FieldName}
            disabled={props.Disabled} 
            dateFormat="dd.MM.yyyy" 
            locale="tr"  
            //selected={startDate} 
            selected={convertYYYYMMDDToDate(props.Data)} 
            onKeyDown={(e) => props.HelpRequested ? (e.key == "F1" ? props.HelpRequested(e) : null) : null} 
            className={clsx("rounded-md p-2 text-left",
                  props.ClassName,
                  props.HasError ? "border-red-200 bg-red-50 border-2" : "border-gray-300 border",
                  props.Disabled ? Constants.InActive : ""
                  )}
            onChange={(date) => props.OnChange ? props.OnChange(convertDateToYYYYMMDD(date)) : null} 
          />  
       </div>
    </div>
  );
}
