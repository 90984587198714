"use client"


import { TrendingUp } from "lucide-react"
import { Bar, BarChart, CartesianGrid, LabelList, XAxis } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shadcn/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/shadcn/ui/chart"
import { useEffect, useState } from "react"
import useHttpClient from "@/hook/useHttpClient"
import IResponseHelper from "@/models/response/IResponseHelper"

export const description = "A bar chart with a label"

const chartData = [
  { month: "January", desktop: 186 },
  { month: "February", desktop: 305 },
  { month: "March", desktop: 237 },
  { month: "April", desktop: 73 },
  { month: "May", desktop: 209 },
  { month: "June", desktop: 214 },
]

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "#00FFBF",
  },
} satisfies ChartConfig

export interface IOrcBarChart {
  Progname: string;
}
class KPIBarChartModel {
  Title: string = "";
  SubTitle: string = "";
  ChartData: KPIBarChartModelDataItem[] = [];
  Footer: string = "";
}

class KPIBarChartModelDataItem {
  DataKey: string = "";
  DataValue: number = 0;
  fill: string = "";

}

export function OrcBarChart(props: IOrcBarChart) {

  //

  const [loaded, setLoaded] = useState(false);
  const { isLoading, request } = useHttpClient();

  let initialKPI: KPIBarChartModel = new KPIBarChartModel();

  const [KPIData, setKPIData ] =  useState<KPIBarChartModel>(initialKPI);

  useEffect(() => {
    if(props.Progname != null)
      fetch();
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await request<IResponseHelper<KPIBarChartModel>>(`/api/v1/program?ProgName=` + props.Progname, "GET");
    if(resp==null) return;
    if (resp.data) {
      setKPIData(resp.data);
      console.log(resp.data);
      setLoaded(true);
      //
    } else {
      setLoaded(true);

    }
    debugger;
  };



  
  //
  return (
    <ChartContainer config={chartConfig}>
      <BarChart
        accessibilityLayer
        data={KPIData.ChartData}
        margin={{
          top: 20,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="DataKey"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />
        <Bar dataKey="DataValue" fill="var(--color-desktop)" radius={8}>
          <LabelList
            position="top"
            offset={12}
            className="fill-foreground"
            fontSize={12}
          />
        </Bar>
      </BarChart>
    </ChartContainer>

  )
}
