import { useTranslation } from "react-i18next";


function BuildHRToday() {
  const { t } = useTranslation();

  return (

<section className="py-32 pb-44 px-10 bg-center bg-gray-800 mb-24"> 
  <h1 className="tracking-tight font-heading text-5xl md:text-10xl text-white mb-4 font-medium">
    Join the Community and <br/>Share Your HCM Knowledge
  
  </h1>
  <p className="text-white text-lg mb-8 max-w-xl">
    Join our community, get education and start building your HCM solution for your company and your customers. Every week on Friday, we have a training session for our community members.
    
  </p>

    <a href="mailto:info@hrsp.com.tr" target="_blank" className="tracking-tight bg-white mx-1 px-5 py-3 h-14 rounded-full hover:bg-gray-50 focus:bg-gray-50 focus:ring-4 focus:ring-gray-300 text-sm font-medium transition duration-200">Apply Now</a>
    <a href="https://orchestrahr-com.gitbook.io/docs" target="_blank" className="tracking-tight bg-white mx-1 px-5 py-3 h-14 rounded-full hover:bg-gray-50 focus:bg-gray-50 focus:ring-4 focus:ring-gray-300 text-sm font-medium transition duration-200">Read Git-Book</a>
 

</section>
  );
}

export default BuildHRToday;


