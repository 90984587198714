import clsx from "clsx";
import { Label } from "@/shadcn/ui/label";
import { Constants } from "@/models/Consts";

export interface IOrcTextBox {
  FieldName: string;
  ClassName?: string;
  CaptionClassName?: string;
  Data: string;
  ParentFieldName: string;
  Disabled?: boolean;
  Required?: boolean;
  HasError?: boolean;
  Caption?: string;
  PlaceHolder?: string;
  ScreenName?: string;
  RollName?: string;
  DomName?: string;
  DataType?: string;
  Type?: string;
  Length?: number;
  Regex?: string | RegExp;
  OnChange?: (_value: string) => void;
  HelpRequested?: (e: any) => void;
}

export default function OrcTextBox(props: IOrcTextBox) {
  //

  return (
    <div className={clsx("flex flex-col gap-1 w-full")}>
      {props.Caption ? (
        <Label
          className={clsx(
            "font-normal",
            props.CaptionClassName ? props.CaptionClassName : ""
          )}
        >
          {props.Caption + (props.Required ? " *" : "")}
        </Label>
      ) : null}
      <div>
        <input
          key={props.FieldName}
          maxLength={props.Length}
          type={props.Type ? props.Type : "text"}
          onKeyDown={(e) =>
            props.HelpRequested
              ? e.key == "F1"
                ? props.HelpRequested(e)
                : null
              : null
          }
          // onChange={(e) => props.OnChange ? props.OnChange(e.target.value) : null}
          onChange={(e) => {
            const value = e.target.value;
            // const regex = props.Regex;
            debugger;

            const regex = props.Regex ? new RegExp(props.Regex) : null;

            // Eğer regex yoksa veya değer regex ile uyumluysa OnChange çağır
            if (!regex || regex.test(value)) {
              props.OnChange?.(value);
            }
            // if (regex && typeof regex !== "string" && regex.test(value)) {
            //   // Eğer regex bir RegExp ise ve test başarılıysa
            //   props.OnChange?.(value);
            // } else if (!regex || typeof regex === "string") {
            //   // Eğer regex bir string ise veya tanımlı değilse
            //   props.OnChange?.(value);
            // }
          }}
          placeholder={props.PlaceHolder}
          className={clsx(
            "rounded-md p-2 text-left border-gray-100 border",
            props.ClassName,
            props.HasError
              ? "border-red-200 bg-red-50 border-2"
              : "border-gray-300 border",
            props.Disabled ? Constants.InActive : ""
          )}
          disabled={props.Disabled}
          value={props.Data}
        />
      </div>
    </div>
  );
}
