import { TrendingDown, TrendingUp } from "lucide-react"
import {
  Label,
  PolarGrid,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
} from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shadcn/ui/card"
import { ChartConfig, ChartContainer } from "@/shadcn/ui/chart"
import { useEffect, useState } from "react"
import useHttpClient from "@/hook/useHttpClient";
import IResponseHelper from "@/models/response/IResponseHelper";
import { Icon } from "@iconify/react"
import clsx from "clsx"
export const description = "A radial chart with a custom shape"

const chartData = [
  { browser: "safari", visitors: 1260, fill: "var(--color-safari)" },
]

const chartConfig = {
  visitors: {
    label: "Visitors",
  },
  safari: {
    label: "Safari",
    color: "#FFBF00",
  },
} satisfies ChartConfig

export interface IOrcRadialChart {
  Progname: string;
  Value?: number;
  EndAngle: number;
  Units?: string;
}

enum Trend {
  Up = 0,
  Down = 1,
}

class KPIRadialChartModel {
  Title: string = "";
  SubTitle: string = "";
  EndAngle: number = 100;
  Value: string = "";
  Units: string = "";
  Footer: string = "";
  ShowTrend: boolean = true;
  Trend: Trend = Trend.Up;
  TrendWord: string = "";
}

export function OrcRadialChart(props: IOrcRadialChart) {

  const [loaded, setLoaded] = useState(false);
  const { isLoading, request } = useHttpClient();

  let initialKPI: KPIRadialChartModel = new KPIRadialChartModel();

  initialKPI.Value = props.Value?.toString() ?? "0";
  if(props.EndAngle != null)
    initialKPI.EndAngle = parseInt(props.EndAngle.toString());
  //
  initialKPI.Units = props.Units?.toString() ?? "";
  //
  //
  const [KPIData, setKPIData ] =  useState<KPIRadialChartModel>(initialKPI);

  useEffect(() => {
    if(props.Progname != null)
      fetch();  
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await request<IResponseHelper<KPIRadialChartModel>>(`/api/v1/program?ProgName=` + props.Progname, "GET");
    if(resp==null) return;
    if (resp.data) {
      setKPIData(resp.data);
      //
    } else {
      setLoaded(true);

    }
    debugger;
  };

  const Trending = (t : Trend) => {
    if(t == Trend.Down){
      return <TrendingDown className="h-4 w-4" />
    }
    else {
      return <TrendingUp className="h-4 w-4" />
    }
  }


  return (
    <ChartContainer config={chartConfig}
    className="mx-auto aspect-square max-h-[250px]"
  >
    <RadialBarChart
      data={chartData}
      endAngle={KPIData.EndAngle}
      innerRadius={80}
      outerRadius={140}
    >
      <PolarGrid
        gridType="circle"
        radialLines={false}
        stroke="none"
        className="first:fill-muted last:fill-background"
        polarRadius={[86, 74]}
      />
      <RadialBar dataKey="visitors" background />
      <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
        <Label
          content={({ viewBox }) => {
            if (viewBox && "cx" in viewBox && "cy" in viewBox) {
              return (
                <text
                  x={viewBox.cx}
                  y={viewBox.cy}
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  <tspan
                    x={viewBox.cx}
                    y={viewBox.cy}
                    className="fill-foreground text-5xl font-bold"
                  >
                    {/* {chartData[0].visitors.toLocaleString()} */}
                    {KPIData.Value}
                  </tspan>
                  <tspan
                    x={viewBox.cx}
                    y={(viewBox.cy || 0) + 24}
                    className="fill-muted-foreground"
                  >
                    {KPIData.Units}
                  </tspan>
                </text>
              )
            }
          }}
        />
      </PolarRadiusAxis>
    </RadialBarChart>
    </ChartContainer>
  )
}
