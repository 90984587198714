import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Label } from "@/shadcn/ui/label";
import { RadioGroup, RadioGroupItem } from "@/shadcn/ui/radio-group";
import { Separator } from "@/shadcn/ui/separator";
import { Toaster } from "@/shadcn/ui/toaster";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "@/shadcn/ui/use-toast";
import { SearchHelp } from "../../comps/SearchHelp";
import DomainService from "@/services/dd/DomainService";
import DataElementService from "@/services/dd/DataElementService";
import TableService from "@/services/dd/TableService";
import StructureService from "@/services/dd/StructureService";
import { Icon } from "@iconify/react";
import LockService from "@/services/dd/LockService";
import { cn } from "@/lib/utils";
import { isNullOrUndefined } from "util";
import ViewService from "@/services/dd/ViewService";
import { OrcScreen } from "@/models/screen/OrcScreen";
import { ScreenEvent } from "@/models/Enums";
import ScreenService from "@/services/program/ScreenService";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/shadcn/ui/dialog";
import OrcTextBox from "../../comps/OrcTextBox";
import OrcText from "../../comps/OrcText";
import { Button } from "@/shadcn/ui/button";
import ObjNameService from "@/services/dd/ObjNameService";
//
enum DDicType {
  domain = "domain",
  dataelement = "dataelement",
  struname = "structure",
  tabname = "table",
  viewname = "view",
}

function DataDictionary() {
  //selected dict element
  const [dictype, setDicType] = useState(DDicType.domain);
  const [domname, setDomName] = useState("");
  const [rollname, setRollName] = useState("");
  const [struname, setStruName] = useState("");
  const [tabname, setTabName] = useState("");
  const [viewname, setViewName] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [pagefound, setPageFound] = useState(false);
  const [unAuthorized, setunAuthorized] = useState(false);
  const navigate = useNavigate();

  const [copyobject,SetCopyObject] = useState("");
  const [copyVisible, setcopyVisible] = useState(false);

  let customObjKey = "X";

  let initialscreen: OrcScreen = new OrcScreen();
  initialscreen.UIPath =  encodeURIComponent(window.location.pathname.toLowerCase());
  initialscreen.HRef = window.location.href;
  initialscreen.ProgName = "";
  initialscreen.ScreenEvent = ScreenEvent.Get;

  const GetHistoryOld = () => {
    if(localStorage.getItem("DICTYPE"))
      {
        const type = localStorage.getItem("DICTYPE");
        switch(type)
        {
          case "domain":
            setDicType(DDicType.domain);
            const _domname = localStorage.getItem("DICNAME");
            if(_domname)
            {
              setDomName(_domname);
            }
            break;
          case "dataelement":
            setDicType(DDicType.dataelement);
            const _dataelement = localStorage.getItem("DICNAME");
            if(_dataelement)
            {
              setRollName(_dataelement);
            }
            break;
          case "structure":
            setDicType(DDicType.struname);
            const _struname = localStorage.getItem("DICNAME");
            if(_struname)
            {
              setStruName(_struname);
            }
            break;
          case "table":
            setDicType(DDicType.tabname);
            const _tabname = localStorage.getItem("DICNAME");
            if(_tabname)
            {
              setTabName(_tabname);
            }
            break;
          case "view":
            setDicType(DDicType.viewname);
            const _viewname = localStorage.getItem("DICNAME");
            if(_viewname)
            {
              setViewName(_viewname);
            }
            break;
        }
  
  
  
      }

  }

  const GetHistory = () => {
    const _domname = localStorage.getItem("_domname");
    if(_domname)
    {
      setDomName(_domname);
    }
    const _rollname = localStorage.getItem("_rollname");
    if(_rollname)
    {
      setRollName(_rollname);
    }
    const _struname = localStorage.getItem("_struname");
    if(_struname)
    {
      setStruName(_struname);
    }
    const _tabname = localStorage.getItem("_tabname");
    if(_tabname)
    {
      setTabName(_tabname);
    }
    const _viewname = localStorage.getItem("_viewname");
    if(_viewname)
    {
      setViewName(_viewname);
    }

    if(localStorage.getItem("DICTYPE"))
      {
        const type = localStorage.getItem("DICTYPE");
        switch(type)
        {
          case "domain":
            setDicType(DDicType.domain);
            break;
          case "dataelement":
            setDicType(DDicType.dataelement);
            break;
          case "structure":
            setDicType(DDicType.struname);
            break;
          case "table":
            setDicType(DDicType.tabname);
            break;
          case "view":
            setDicType(DDicType.viewname);
            break;
        }
  
  
  
      }

  }
  
  useState(() => {
    //get history
    GetHistory();
    
    
  });


  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await ScreenService.post(initialscreen);
    if (resp.data) {
      console.log(resp.data);
      setunAuthorized(resp.data.UnAuthorized);
    } else {
      setLoaded(true);
      setPageFound(false);
    }
    debugger;
  };



  const Display = async () => {
    if (dictype === DDicType.domain) {
      if (domname.length > 0) {
        var domExist = await DomainService.isExist(domname);
        if (domExist) {
          localStorage.setItem("_domname", domname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/domain/display/" + domname);
        } else {
          toast({
            title: "Domain does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select domain",
        });
      }
    } else if (dictype === DDicType.dataelement) {
      if (rollname.length > 0) {
        var isExist = await DataElementService.isExist(rollname);
        if (isExist) {
          localStorage.setItem("_dataelement", rollname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/dataelement/display/" + rollname);
        } else {
          toast({
            title: "Data element does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select data-element",
        });
      }
    } else if (dictype === DDicType.tabname) {
      if (tabname.length > 0) {
        var isExist = await TableService.isExist(tabname);
        if (isExist) {
          localStorage.setItem("_tabname", tabname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/table/display/" + tabname);
        } else {
          toast({
            title: "Table does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select table",
        });
      }
    } else if (dictype === DDicType.struname) {
      if (struname.length > 0) {
        var isExist = await StructureService.isExist(struname);
        if (isExist) {
          localStorage.setItem("_struname", struname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/structure/display/" + struname);
        } else {
          toast({
            title: "Structure does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select structure",
        });
      }
    } else if (dictype === DDicType.viewname) {
      if (viewname.length > 0) {
        var isExist = await ViewService.isExist(viewname);
        if (isExist) {
          localStorage.setItem("_viewname", viewname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/view/display/" + viewname);
        } else {
          toast({
            title: "View does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select view",
        });
      }
    }
  };

  const Change = async () => {
    if (dictype === DDicType.domain) {
      if (domname.length > 0) {
        var domExist = await DomainService.isExist(domname);
        if (domExist) {
          var res = await DomainService.IsNameOk(domname);
          if (res?.IsNameOk == true) {
            localStorage.setItem("_domname", domname);
            localStorage.setItem("DICTYPE", dictype);
            navigate("/apps/domain/change/" + domname);
          }
          else
          {
            toast({
              title: "Standart objects cannot be changed.",
              description: res?.Reason,
            });
          }
        } else {
          toast({
            title: "Domain does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select domain",
        });
      }
    } else if (dictype === DDicType.dataelement) {
      if (rollname.length > 0) {
        var DEExist = await DataElementService.isExist(rollname);
        if (DEExist) {
          var res = await DataElementService.IsNameOk(rollname);
          if (res?.IsNameOk == true) {
            localStorage.setItem("_rollname", rollname);
            localStorage.setItem("DICTYPE", dictype);
            navigate("/apps/dataelement/change/" + rollname);
          }
          else
          {
            toast({
              title: "Standart objects cannot be changed.",
              description: res?.Reason,
            });
          }

        } else {
          toast({
            title: "Data element does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select data-element",
        });
      }
    } else if (dictype === DDicType.tabname) {
      if (tabname.length > 0) {
        var TABExist = await TableService.isExist(tabname);
        if (TABExist) {
          var res = await TableService.IsNameOk(tabname);
          if (res?.IsNameOk == true) {
            localStorage.setItem("_tabname", tabname);
            localStorage.setItem("DICTYPE", dictype);
            navigate("/apps/table/change/" + tabname);
          }
          else
          {
            toast({
              title: "Standart objects cannot be changed.",
              description: res?.Reason,
            });
          }
        } else {
          toast({
            title: "Table does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select table",
        });
      }
    } else if (dictype === DDicType.struname) {
      if (struname.length > 0) {
        var TABExist = await StructureService.isExist(struname);
        if (TABExist) {
          var res = await StructureService.IsNameOk(struname);
          if (res?.IsNameOk == true) {
            localStorage.setItem("_struname", struname);
            localStorage.setItem("DICTYPE", dictype);
            navigate("/apps/structure/change/" + struname);
          }
          else
          {
            toast({
              title: "Standart objects cannot be changed.",
              description: res?.Reason,
            });
          }
        } else {
          toast({
            title: "Structure does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select structure",
        });
      }
    } else if (dictype === DDicType.viewname) {
      if (viewname.length > 0) {
        var TABExist = await ViewService.isExist(viewname);
        if (TABExist) {
          localStorage.setItem("_viewname", viewname);
          localStorage.setItem("DICTYPE", dictype);
          navigate("/apps/view/change/" + viewname);
        } else {
          toast({
            title: "View does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select view",
        });
      }
    }
  };

  const Copy = async () => {
    if (dictype === DDicType.domain) {
      SetCopyObject(domname.toUpperCase());  
    } else if (dictype === DDicType.dataelement) {
      SetCopyObject(rollname.toUpperCase());  
    } else if (dictype === DDicType.struname) {
      SetCopyObject(struname.toUpperCase());  
    } else if (dictype === DDicType.tabname) {
      SetCopyObject(tabname.toUpperCase());  
    } else if (dictype === DDicType.viewname) {
      SetCopyObject(viewname.toUpperCase());  
    }
    setcopyVisible(true);
  } 

  const Copy2 = async () => {

    if (copyobject.length > 0) {
      //Copy Domain Start
      if (dictype === DDicType.domain) {
        var domExist = await DomainService.isExist(copyobject);
        if (domExist === false) {
          var res = await ObjNameService.IsNameOk(copyobject);
          if (res?.IsNameOk == true) {
            var copyresult = await DomainService.copy(copyobject, domname);
            if(copyresult === true)
            {
              toast({
                title: "Domain " + copyobject + " has been copied.",
                description: "You can modify your new domain.",
              });
    
              setDomName(copyobject);
              setcopyVisible(false);
            }
            else
            {
              toast({
                title: "Domain " + copyobject + " could not be copied.",
                description: "Please try again.",
              });
            }
          }
          else
          {
            toast({
              title: "Check your domain name",
              description: res?.Reason,
            });

          }
          //setcopyVisible(false);

          //
        } else {
          toast({
            title: "Domain " + copyobject + " already exist.",
            description: "Please choose different domain name.",
          });
        }
      }
      //Copy Domain End
      //Copy Data Element Start
      else if (dictype === DDicType.dataelement) {
        var isExist = await DataElementService.isExist(copyobject);
        if (isExist === false) {
          var res = await ObjNameService.IsNameOk(copyobject);
          if (res?.IsNameOk == true)
          {
            var copyresult = await DataElementService.copy(copyobject, rollname);
            if(copyresult === true)
            {
              toast({
                title: "Data element " + copyobject + " has been copied.",
                description: "You can modify your new data element.",
              });
    
              setRollName(copyobject);
            }
            else
            {
              toast({
                title: "Data element " + copyobject + " could not be copied.",
                description: "Please try again.",
              });
            }
          }
          else
          {
            toast({
              title: "Check your data element name",
              description: res?.Reason,
            });
          }


          setcopyVisible(false);

          //
        } else {
          toast({
            title: "Data element " + copyobject + " already exist.",
            description: "Please choose different data element name.",
          });
        }
      }
      //Copy Data Element End
      //Copy Structure Start
      else if (dictype === DDicType.struname) {
          var isexist = await StructureService.isExist(copyobject);
          if (isexist === false) {
            var res = await ObjNameService.IsNameOk(copyobject);
            if (res?.IsNameOk == true)
            {
              var copyresult = await StructureService.copy(copyobject, struname);
              if(copyresult === true)
              {
                toast({
                  title: "Structure " + copyobject + " has been copied.",
                  description: "You can modify your new structure.",
                });
      
                setStruName(copyobject);
              }
              else
              {
                toast({
                  title: "Structure " + copyobject + " could not be copied.",
                  description: "Please try again.",
                });
              }

            }
            else
            {
              toast({
                title: "Check your structure name",
                description: res?.Reason,
              });
            }


            setcopyVisible(false);
  
            //
          } else {
            toast({
              title: "Structure " + copyobject + " already exist.",
              description: "Please choose different structure.",
            });
          }
        }
      //Copy Structure End
      //Copy Table Start
      else if (dictype === DDicType.tabname) {
        var isexist = await TableService.isExist(copyobject);
        if (isexist === false) {
          var res = await DomainService.IsNameOk(copyobject);
          if (res?.IsNameOk == true)
          {
            var copyresult = await TableService.copy(copyobject, tabname);
            if(copyresult === true)
            {
              toast({
                title: "Table " + copyobject + " has been copied.",
                description: "You can modify your new table.",
              });
    
              setStruName(copyobject);
            }
            else
            {
              toast({
                title: "Table " + copyobject + " could not be copied.",
                description: "Please try again.",
              });
            }
  
            setTabName(copyobject);
            setcopyVisible(false);
          }
          else
          {
            toast({
              title: "Check your table name",
              description: res?.Reason,
            });
          }

          

          //
        } else {
          toast({
            title: "Table " + copyobject + " already exist.",
            description: "Please choose different table.",
          });
        }
      }
      //Copy Table End
      //Copy View Start
      else if (dictype === DDicType.viewname) {
        var isexist = await ViewService.isExist(copyobject);
        if (isexist === false) {
          var copyresult = await ViewService.copy(copyobject, viewname);
          //
          if(copyresult === true)
            {
              toast({
                title: "View " + copyobject + " has been copied.",
                description: "You can modify your new view.",
              });
    
              setStruName(copyobject);
            }
            else
            {
              toast({
                title: "View " + copyobject + " could not be copied.",
                description: "Please try again.",
              });
            }

          setViewName(copyobject);
          setcopyVisible(false);

          //
        } else {
          toast({
            title: "View " + copyobject + " already exist.",
            description: "Please choose different view.",
          });
        }
      }
      //Copy View End

    } //if (copyobject.length > 0)
    


  }

  const Create = async () => {
    if (dictype === DDicType.domain) {
      //
      if (domname.length > 0) {
        if (domname.includes(" ")) {
          toast({
            title: "Check Domain Name",
            description: "Dictionary element names should have no blank.",
          });
        } else {
          var domExist = await DomainService.isExist(domname);
          if (domExist === false) {
            var res = await DomainService.IsNameOk(domname);
            if (res?.IsNameOk == true) {
              localStorage.setItem("_domname", domname);
              localStorage.setItem("DICTYPE", dictype);
                navigate("/apps/domain/create/" + domname);
            }
            else
            {
              toast({
                title: "Check your domain name",
                description: res?.Reason,
              });
            }

          } else {
            toast({
              title: "Domain already exist.",
              description:
                "Domain already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter domain name",
          description: "Please enter domain name to be created.",
        });
      }
      //
    } else if (dictype === DDicType.dataelement) {
      //
      if (rollname.length > 0) {
        if (rollname.includes(" ")) {
          toast({
            title: "Check Data Element Name",
            description: "Dictionary element names should have no blank.",
          });
        } else {
          var isExist = await DataElementService.isExist(rollname);
          if (isExist === false) {
            var res = await DataElementService.IsNameOk(rollname);
            if (res?.IsNameOk == true) {
              localStorage.setItem("_rollname", rollname);
              localStorage.setItem("DICTYPE", dictype);
              navigate("/apps/dataelement/create/" + rollname);
            }
            else
            {
              toast({
                title: "Check your data element name",
                description: res?.Reason,
              });
            }
          } else {
            toast({
              title: "Data element already exist.",
              description:
                "Data element already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter data element name",
          description: "Please enter data element name to be created.",
        });
      }
      //
    } else if (dictype === DDicType.tabname) {
      //
      if (tabname.length > 0) {
        if (tabname.includes(" ")) {
          toast({
            title: "Check Table Name",
            description: "Dictionary element names should have no blank.",
          });
        } else {
          var isExist = await TableService.isExist(tabname);
          if (isExist === false) {


            var res = await TableService.IsNameOk(tabname);
            if (res?.IsNameOk == true) {
              localStorage.setItem("_tabname", tabname);
              localStorage.setItem("DICTYPE", dictype);
              navigate("/apps/table/create/" + tabname);
            }
            else
            {
              toast({
                title: "Check your table name",
                description: res?.Reason,
              });
            }

          } else {
            toast({
              title: "Table already exist.",
              description:
                "Table already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter table name",
          description: "Please enter table name to be created.",
        });
      }
      //
    } else if (dictype === DDicType.struname) {
      //
      if (struname.length > 0) {
        if (struname.includes(" ")) {
          toast({
            title: "Check Structure Name",
            description: "Structure names should have no blank.",
          });
        } else {
          var isExist = await StructureService.isExist(struname);
          if (isExist === false) {


            var res = await StructureService.IsNameOk(struname);
            if (res?.IsNameOk == true) {
              localStorage.setItem("_struname", struname);
              localStorage.setItem("DICTYPE", dictype);
              navigate("/apps/structure/create/" + struname);
            }
            else
            {
              toast({
                title: "Check your structure name",
                description: res?.Reason,
              });
            }



          } else {
            toast({
              title: "Structure already exist.",
              description:
                "Structure already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter structure name",
          description: "Please enter structure name to be created.",
        });
      }
      //
    } else if (dictype === DDicType.viewname) {
      //
      if (viewname.length > 0) {
        if (viewname.includes(" ")) {
          toast({
            title: "Check View Name",
            description: "View names should have no blank.",
          });
        } else {
          var isExist = await ViewService.isExist(viewname);
          if (isExist === false) {
            localStorage.setItem("DICNAME", viewname);
            localStorage.setItem("DICTYPE", dictype);
            navigate("/apps/view/create/" + viewname);
          } else {
            toast({
              title: "View already exist.",
              description:
                "View already created, check your name and try again.",
            });
          }
        }
      } else {
        toast({
          title: "Enter view name",
          description: "Please enter view name to be created.",
        });
      }
      //
    }
  };

  const Delete = async () => {
    if (dictype === DDicType.domain) {
      if (domname.length > 0) {
        var domExist = await DomainService.isExist(domname);
        if (domExist) {
          var res = await DomainService.IsNameOk(domname);
          if (res?.IsNameOk == true) {
            navigate("/apps/domain/delete/" + domname);
          }
          else
          {
            toast({
              title: "Standart objects cannot be deleted.",
              description: res?.Reason,
            });
          }
        } else {
          toast({
            title: "Domain does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select domain",
        });
      }
    } else if (dictype === DDicType.dataelement) {
      if (rollname.length > 0) {
        var isExist = await DataElementService.isExist(rollname);
        if (isExist) {
          var res = await DataElementService.IsNameOk(rollname);
          if (res?.IsNameOk == true) {
            navigate("/apps/dataelement/delete/" + rollname);
          }
          else
          {
            toast({
              title: "Standart objects cannot be deleted.",
              description: res?.Reason,
            });
          }
        } else {
          toast({
            title: "Data element does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select data-element",
        });
      }
    } else if (dictype === DDicType.tabname) {
      if (tabname.length > 0) {
        var isExist = await TableService.isExist(tabname);
        if (isExist) {
          var res = await TableService.IsNameOk(tabname);
          if (res?.IsNameOk == true) {
            navigate("/apps/table/delete/" + tabname);
          }
          else
          {
            toast({
              title: "Standart objects cannot be deleted.",
              description: res?.Reason,
            });
          }
        } else {
          toast({
            title: "Table does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select table",
        });
      }
    } else if (dictype === DDicType.struname) {
      if (struname.length > 0) {
        var isExist = await StructureService.isExist(struname);
        if (isExist) {


          var res = await StructureService.IsNameOk(struname);
          if (res?.IsNameOk == true) {
            navigate("/apps/structure/delete/" + struname);
          }
          else
          {
            toast({
              title: "Standart objects cannot be deleted.",
              description: res?.Reason,
            });
          }          


        } else {
          toast({
            title: "Structure does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select structure",
        });
      }
    } else if (dictype === DDicType.viewname) {
      if (viewname.length > 0) {
        var isExist = await ViewService.isExist(viewname);
        if (isExist) {
          navigate("/apps/view/delete/" + viewname);
        } else {
          toast({
            title: "View does not exist.",
            description: "Please check your name and try again.",
          });
        }
      } else {
        toast({
          title: "Please select view",
        });
      }
    }
  };

  const OnDomainSelect = (x: string) => {
    setDomName(x);
  };
  const OnRollNameSelect = (x: string) => {
    setRollName(x);
  };
  const OnStructureSelect = (x: string) => {
    setStruName(x);
  };
  const OnViewNameSelect = (x: string) => {
    setViewName(x);
  };

  const OnTabNameSelect = (x: string) => {
    setTabName(x);
  };

  function decodeTurkishCharacters(text: string) {
    return text
      .replace(/\ğ/g, "g")
      .replace(/\ü/g, "u")
      .replace(/\ş/g, "s")
      .replace(/\ı/g, "i")
      .replace(/\ö/g, "o")
      .replace(/\ç/g, "c");
  }
  
  const UnAuthorizedView = (
    <div className="min-h-[500px] flex flex-col items-center justify-center text-4xl text-primary">
      <div className="font-bold text-6xl mb-4">401</div>
      <div>
        Page{" "}
        <span className="text-red-600">
          {decodeURIComponent(initialscreen.ProgName.toLowerCase())}
        </span>{" "}
        Not authorized
      </div>
    </div>
  );

  return (unAuthorized === false ? (  
    <div className="bg-white w-full h-screen p-4">
      <h1 className="font-bold text-2xl">Data Dictionary</h1>
      <div className="mb-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                className="hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/apps/exec/basis/develop");
                }}
              >
                Developments
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Data Dictionary</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <Separator className="my-4" />
      {/* Commands Start */}
      <div className="flex flex-row flex-nowrap my-2 gap-1 mb-10">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Create()}
          title="Create"
        >
          <Icon
            icon="akar-icons:paper"
            width="18"
            height="18"
            className="text-black bg-white"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Copy()}
          title="Copy"
        >
          <Icon
            icon="icons8:copy"
            width="20"
            height="20"
            className=""
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Change()}
          title="Change"
        >
          <Icon
            icon="solar:pen-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Display()}
          title="Display"
        >
          <Icon
            icon="tabler:zoom"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Delete()}
          title="Delete"
        >
          <Icon
            icon="material-symbols-light:delete-outline"
            width="18"
            height="18"
            className="text-black"
          />
        </button>
      </div>
      {/* Commands End */}
      <div>
        <RadioGroup defaultValue={dictype}>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="domain"
                onClick={() => setDicType(DDicType.domain)}
              />
              <Label className="ms-2">Domain</Label>
            </div>
            <SearchHelp
              SHName="DOMNAME"
              className="ms-20 w-64"
              onChange={(e) => { var val = e.target.value; val = decodeTurkishCharacters(val); val=val.toUpperCase(); setDomName(val)}}
              value={domname}
              InputClassName="uppercase"
              OnSelect={(x) => OnDomainSelect(x.toUpperCase())}
            />
          </div>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="dataelement"
                onClick={() => setDicType(DDicType.dataelement)}
              />
              <Label className="ms-2">Data Element</Label>
            </div>
            <SearchHelp
              SHName="ROLLNAME"
              className="ms-20 uppercase w-64"
              //onChange={(e) => setRollName(e.target.value.toUpperCase())}
              onChange={(e) => { var val = e.target.value; val = decodeTurkishCharacters(val); val=val.toUpperCase(); setRollName(val)}}
              value={rollname}
              InputClassName="uppercase"
              OnSelect={(x) => OnRollNameSelect(x.toUpperCase())}
            />
          </div>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="structure"
                onClick={() => setDicType(DDicType.struname)}
              />
              <Label className="ms-2">Structure</Label>
            </div>
            <SearchHelp
              SHName="STRUNAME"
              className="ms-20 w-64"
             // onChange={(e) => setStruName(e.target.value.toUpperCase())}
              onChange={(e) => { var val = e.target.value; val = decodeTurkishCharacters(val); val=val.toUpperCase(); setStruName(val)}}
              value={struname}
              InputClassName="uppercase"
              OnSelect={(x) => OnStructureSelect(x.toUpperCase())}
            />
          </div>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="table"
                onClick={() => setDicType(DDicType.tabname)}
              />
              <Label className="ms-2">Table</Label>
            </div>
            <SearchHelp
              SHName="TABNAME"
              className="ms-20 w-64"
              //onChange={(e) => setTabName(e.target.value.toUpperCase())}
              onChange={(e) => { var val = e.target.value; val = decodeTurkishCharacters(val); val=val.toUpperCase(); setTabName(val)}}
              value={tabname}
              InputClassName="uppercase"
              OnSelect={(x) => OnTabNameSelect(x.toUpperCase())}
            />
          </div>
          <div className="flex items-center">
            <div className="w-28">
              <RadioGroupItem
                value="view"
                onClick={() => setDicType(DDicType.viewname)}
              />
              <Label className="ms-2">View</Label>
            </div>
            <SearchHelp
              SHName="VIEWNAME"
              className="ms-20 w-64"
              //onChange={(e) => setViewName(e.target.value.toUpperCase())}
              onChange={(e) => { var val = e.target.value; val = decodeTurkishCharacters(val); val=val.toUpperCase();  setViewName(val)}}
              value={viewname}
              InputClassName="uppercase"
              OnSelect={(x) => OnViewNameSelect(x.toUpperCase())}
            />
          </div>
        </RadioGroup>
      </div>
      <Dialog open={copyVisible} onOpenChange={setcopyVisible}>
            <DialogContent className="sm:max-w-md font-sapFont">
            <DialogHeader>
                <DialogTitle>Copy</DialogTitle>
                {/* <DialogDescription>
                    Do not forget to press save button after submitting new record.

                </DialogDescription> */}
            </DialogHeader>
            <div>
                <OrcTextBox FieldName="textCopyObject" ParentFieldName="#" Data={copyobject} OnChange={(e) => SetCopyObject(e.toUpperCase())} ClassName="w-full"></OrcTextBox>
                {dictype == DDicType.domain && <OrcText FieldName="btnCopyText" ParentFieldName="#" Text={ "from " + domname.toUpperCase()} ClassName="w-full"></OrcText>}
                {dictype == DDicType.dataelement && <OrcText FieldName="btnCopyText" ParentFieldName="#" Text={ "from " + rollname.toUpperCase()} ClassName="w-full"></OrcText>}
                {dictype == DDicType.struname && <OrcText FieldName="btnCopyText" ParentFieldName="#" Text={ "from " + struname.toUpperCase()} ClassName="w-full"></OrcText>}
                {dictype == DDicType.tabname && <OrcText FieldName="btnCopyText" ParentFieldName="#" Text={ "from " + tabname.toUpperCase()} ClassName="w-full"></OrcText>}
                {dictype == DDicType.viewname && <OrcText FieldName="btnCopyText" ParentFieldName="#" Text={ "from " + viewname.toUpperCase()} ClassName="w-full"></OrcText>}
                
            </div>
            <DialogFooter className="sm:justify-end">
                <DialogClose asChild>
                    {/* <Button type="button" variant="secondary" onClick={() => { setcopyVisible(false) }}>
                        Close
                    </Button> */}
                    <Button type="button" className="bg-blue-800" onClick={Copy2} disabled={copyobject.length < 4}>
                        Copy
                    </Button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    </Dialog>
      <Toaster />
    </div>) : UnAuthorizedView
  )
}

export default DataDictionary;
