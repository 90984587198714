// import { Link } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function HRSystem() {
  const { t, i18n } = useTranslation("", { keyPrefix: "HRSystem" });

  return (
    <section className="px-8 md:px-24 mt-24 mb-6">
      <div className="flex flex-wrap items-center -m-4">
        <div className="w-full lg:w-1/2 p-4">
          <p className="tracking-tight text-gray-500 font-medium mb-4">{t("Word1")}</p>
          <h1 className="font-heading tracking-tight text-4xl md:text-6xl font-medium max-w-sm md:max-w-2xl mb-4">{t("Word2")}</h1>
          <p className="tracking-tight text-gray-700 text-lg mb-7 max-w-xl">
            {t("Word3")}
          </p>
          <div className="flex flex-wrap items-center gap-6">
            {/* <Link to="/contact" className="bg-black h-11 rounded-full px-6 py-4 inline-flex items-center justify-center hover:bg-orange-600 focus:bg-orange-500 focus:ring-4 focus:ring-orange-200 transition duration-200 tracking-tight text-white">{t("Word4")}</Link> */}
            <a href="mailto:info@hrsp.com.tr" className="bg-black h-11 rounded-full px-6 py-4 inline-flex items-center justify-center hover:bg-orange-600 focus:bg-orange-500 focus:ring-4 focus:ring-orange-200 transition duration-200 tracking-tight text-white">{t("Word4")}</a>

            <div className="flex flex-wrap items-center gap-3">
              <a href="#" className="rounded-full border border-gray-200 bg-white hover:bg-gray-50 focus:bg-gray-50 focus:ring-4 focus:ring-gray-200 transition duration-200 w-11 h-11 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
                  <path d="M2 3.5C2 2.94772 2.44772 2.5 3 2.5H5.15287C5.64171 2.5 6.0589 2.85341 6.13927 3.3356L6.87858 7.77147C6.95075 8.20451 6.73206 8.63397 6.3394 8.8303L4.79126 9.60437C5.90756 12.3783 8.12168 14.5924 10.8956 15.7087L11.6697 14.1606C11.866 13.7679 12.2955 13.5492 12.7285 13.6214L17.1644 14.3607C17.6466 14.4411 18 14.8583 18 15.3471V17.5C18 18.0523 17.5523 18.5 17 18.5H15C7.8203 18.5 2 12.6797 2 5.5V3.5Z" fill="#FF8A0A"></path>
                </svg>
              </a>
              <p className="tracking-tight text-lg font-semibold">+90 555 555 4 777</p>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2 p-4">
          <img className="lg:ml-auto object-cover" src="images/products/WebSiteHomeImage.jpg" alt="OrchestraHCM" />
        </div>
      </div>
    </section>
  );
}

export default HRSystem;
