import { ComponentProps, useEffect, useState } from "react"
// import formatDistanceToNow from "date-fns/formatDistanceToNow"

import { cn } from "@/lib/utils"
import { ScrollArea } from "@/shadcn/ui/scroll-area"
import { Mail } from "./data"
import { Badge } from "@/shadcn/ui/badge"
import { formatDistanceToNow } from "date-fns/formatDistanceToNow"
import { useMail } from "./useMail"
import useHttpClient from "@/hook/useHttpClient"
import IResponseHelper from "@/models/response/IResponseHelper"
import { InboxItem } from "@/models/Types"
import { Navigate, useNavigate } from "react-router-dom"
// import { Badge } from "@/registry/new-york/ui/badge"
// import { ScrollArea } from "@/registry/new-york/ui/scroll-area"
// import { Separator } from "@/registry/new-york/ui/separator"
// import { Mail } from "@/app/(app)/examples/mail/data"
// import { useMail } from "@/app/(app)/examples/mail/use-mail"

interface MailListProps {
  items: InboxItem[]
}

export function MailList({ items }: MailListProps) {
// export function MailList() {
  const navigate = useNavigate();
  //
  const [mail, setMail] = useMail();
  const { isLoading, request } = useHttpClient();
  const [loaded, setLoaded] = useState(false);
  // let items: InboxItem[] = [];
  //
  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    setLoaded(false);
    const resp = await request<IResponseHelper<InboxItem[]>>(`/api/v1/inbox/getinbox`, "GET");
    if(resp==null) return;
    //const resp = await ScreenService.post(initialscreen);
    if (resp.data) {
      setLoaded(true);
      console.log("Inbox", resp.data);  

    }
    debugger;
  };

  const GoToWorkflow = (_url : string) => {
    //alert(_url);
    navigate(_url);
  }

  return (
    <ScrollArea className="h-screen">
      <div className="flex flex-col gap-2 p-4 pt-0">
        {items.map((item) => (
          <button
            key={item.Id}
            className={cn(
              "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
              // mail.selected === item.Id && "bg-muted"
            )}
            onClick={() => GoToWorkflow(item.Url)}
            // onClick={() =>
            //   setMail({
            //     ...mail,
            //    // selected: item.Id,
            //   })
            //}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">{item.Ename}</div>
                  {/* {!item.read && ( */}
                  {!false && (
                    <span className="flex h-2 w-2 rounded-full bg-blue-600" />
                  )}
                </div>
                <div
                  className={cn(
                    "ml-auto text-xs",
                    //mail.selected === item.Id
                    false
                      ? "text-foreground"
                      : "text-muted-foreground"
                  )}
                >
                  {formatDistanceToNow(new Date(item.CreatedOn), {
                    addSuffix: true,
                  })}
                </div>
              </div>
              <div className="text-xs font-medium">{item.Subject}</div>
            </div>
            <div className="line-clamp-2 text-xs text-muted-foreground">
              {item.Description.substring(0, 300)} 
            </div>
            {/* {item.labels.length ? (
              <div className="flex items-center gap-2">
                {item.labels.map((label) => (
                  <Badge key={label} variant={getBadgeVariantFromLabel(label)}>
                    {label}
                  </Badge>
                ))}
              </div>
            ) : null} */}
          </button>
        ))}
      </div>
    </ScrollArea>
  )
}

function getBadgeVariantFromLabel(
  label: string
): ComponentProps<typeof Badge>["variant"] {
  if (["work"].includes(label.toLowerCase())) {
    return "default"
  }

  if (["personal"].includes(label.toLowerCase())) {
    return "outline"
  }

  return "secondary"
}