import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import React, { useEffect, useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/shadcn/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shadcn/ui/breadcrumb";
import { Separator } from "@/shadcn/ui/separator";
import { useNavigate, useParams } from "react-router-dom";
import TableService from "@/services/dd/TableService";
import { SearchHelp } from "../../comps/SearchHelp";
import DataElementService from "@/services/dd/DataElementService";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import { Icon } from "@iconify/react";
import { Structure } from "@/models/datadictionary/Structure";
import StructureService from "@/services/dd/StructureService";
import { EditType, View } from "@/models/datadictionary/View";
import ViewService from "@/services/dd/ViewService";
import { Checkbox } from "@/shadcn/ui/checkbox";
import DevClass from "../../comps/DevClass";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";
import SortType from "../../comps/SortType";
import ModuleSelect from "../../comps/ModuleSelect";
//
function DeleteViewDetail() {
  const navigate = useNavigate();

  const routeParams = useParams();
  console.log("routeParams = ", JSON.stringify(routeParams));
  const BreadcrumbPart = () => (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/tiles/develop");
            }}
          >
            Developments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbLink
            className="hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate("/apps/ddic");
            }}
          >
            Data Dictionary
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>View Detail</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );

  let InitialTable: View = {
    ViewName: routeParams.tabname ? routeParams.tabname : "",
    KeyTable: "",
    Outsys: false,
    ModuleCode: "SYSTEM",
    Enable_Add: true,
    Enable_Mod: true,
    Enable_Det: true,
    Enable_Del: true,
    Enable_Exc: true,
    MaxRow: 50,
    EditType: EditType.Inline,
    Definition: "",
    LastChanged: "HRSP",
    LastChangedOn: "20240101",
    ViewType: "CONFIG",
    SourceName: "",
    ExitProg: "",
    FilterText: "",
    Columns: [],
    Relations: [],
  };

  for (let index = 0; index < 10; index++) {
    InitialTable.Columns.push({
      TabName: "",
      DScreen: true,
      OScreen: true,
      Enabled: true,
      Obligatory: true,
      SourceName: "",
      DefaultValue: "",
      Primary: false,
      Seqnr: 0,
      SortOrder: 0,
      SortType: 0,
      FieldName: "",
      Rollname: "",
      Domname: "",
      Datatype: "",
      Definition: "",
      FieldType: "",
      Length: 0,
    });
  }

  const [PageObject, SetPage] = useState(InitialTable);
  const [loaded, SetLoaded] = useState(false);

  const [canbeChanged, setCanBeChanged] = useState(false);

  useEffect(() => {
    SetLoaded(false);
    fetch();
    SetLoaded(true);
  }, []);

  const fetch = async () => {
    if (routeParams.tabname) {
      const resp = await ViewService.getByName(routeParams.tabname);
      if (resp) {
        if (resp) {
          SetPage(resp);
          // if (resp.Status == "A") setCanBeChanged(false);
          // else setCanBeChanged(true);
        } else {
          //toast.success(resp.Error); // Displays a success message
          debugger;
          // SetPage(resp.error);
        }
      }
    }
  };

  const PropChanged = (fname: string, val: any) => {
    if (fname === "TabName") {
      let newobj: View = { ...PageObject, ViewName: val };
      SetPage(newobj);
    } else if (fname === "Definition") {
      let newobj: View = { ...PageObject, Definition: val };
      SetPage(newobj);
    } 
  };

  const FieldListChanged = (fname: string, ind: number, val: any) => {
    if (fname === "RollName") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].Rollname = val;
      SetPage(newobj);
    } else if (fname === "TabName") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].FieldName = val;
      SetPage(newobj);
    } else if (fname === "FieldName") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].FieldName = val;
      SetPage(newobj);
    } else if (fname === "OScreen") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].FieldName = val;
      SetPage(newobj);
    } else if (fname === "DScreen") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].FieldName = val;
      SetPage(newobj);
    } else if (fname === "Enabled") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].FieldName = val;
      SetPage(newobj);
    } else if (fname === "Obligatory") {
      let newobj: View = { ...PageObject };
      newobj.Columns[ind].FieldName = val;
      SetPage(newobj);
    }
  };

  const OnRollNameSelect = async (x: string, ind: number) => {
    let newobj: View = { ...PageObject };
    newobj.Columns[ind].Rollname = x;

    var delData = await DataElementService.getByName(x);
    if (delData) {
      newobj.Columns[ind].Domname = delData.DomName;
      newobj.Columns[ind].Datatype = delData.Datatype;
      newobj.Columns[ind].Definition = delData.Definition;
      newobj.Columns[ind].Length = delData.Length;
    }

    SetPage(newobj);
  };

  const OnRollNameBlur = async (x: string, ind: number) => {
    let newobj: View = { ...PageObject };
    newobj.Columns[ind].Rollname = x;
    
    var delData = await DataElementService.getByName(x);
    if (delData) {
      newobj.Columns[ind].Domname = delData.DomName;
      newobj.Columns[ind].Datatype = delData.Datatype;
      newobj.Columns[ind].Definition = delData.Definition;
      newobj.Columns[ind].Length = delData.Length;

      // newobj.Columns[ind].TabName = delData.TabName;
      // newobj.Columns[ind].OScreen = delData.Length;
      // newobj.Columns[ind].DScreen = delData.Length;
      // newobj.Columns[ind].Enabled = delData.Length;
      // newobj.Columns[ind].Obligatory = delData.Length;
    } else {
      newobj.Columns[ind].Domname = "";
      newobj.Columns[ind].Datatype = "";
      newobj.Columns[ind].Definition = "";
      newobj.Columns[ind].Length = 0;
    }

    SetPage(newobj);
  };

  /*
    Saves
  */
  const Save = async () => {
    debugger;
    SetLoaded(false);
    let newSaveobj: View = { ...PageObject, Columns: [] };

    PageObject.Columns.forEach((col) => {
      if (col.FieldName) {
        newSaveobj.Columns.push({
          TabName: col.TabName,
          DScreen: col.DScreen,
          OScreen: col.OScreen,
          Enabled: col.Enabled,
          Obligatory: col.Obligatory,
          Seqnr: col.Seqnr,
          SourceName: col.SourceName,
          Primary: col.Primary,
          SortOrder: col.SortOrder,
          SortType:col.SortType,
          FieldName: col.FieldName,
          Rollname: col.Rollname,
          Domname: col.Domname,
          Datatype: col.Datatype,
          Definition: col.Definition,
          FieldType: col.FieldType, 
          Length: col.Length,
        });
      }
    });

    const resp = await ViewService.post(newSaveobj);
    if (resp.data) {
      //navigate("/apps/ddic");
      let newSaveobj1: View = { ...newSaveobj};
      SetPage(newSaveobj1);
      toast({
        title: "Successfull:)",
        description: "View activated and created in database.",
      });
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }

    SetLoaded(true);
  };

  /*
    Saves and activates
  */
  const Activate = async () => {
    debugger;
    SetLoaded(false);
    let newSaveobj: View = { ...PageObject, Columns: [] };

    PageObject.Columns.forEach((col) => {
      if (col.FieldName) {
        newSaveobj.Columns.push({
          TabName: col.TabName,
          DScreen: col.DScreen,
          OScreen: col.OScreen,
          Enabled: col.Enabled,
          Obligatory: col.Obligatory,
          Seqnr: col.Seqnr,
          SourceName: col.SourceName,
          Primary: col.Primary,
          SortOrder: col.SortOrder,
          SortType:col.SortType,
          FieldName: col.FieldName,
          Rollname: col.Rollname,
          Domname: col.Domname,
          Datatype: col.Datatype,
          Definition: col.Definition,
          FieldType: col.FieldType,
          Length: col.Length,
        });
      }
    });
    const resp = await ViewService.activate(newSaveobj);
    debugger;
    if (resp.data) {
      //navigate("/apps/ddic");
      let newSaveobj: View = { ...PageObject };
      SetPage(newSaveobj);
      toast({
        title: "Successfull:)",
        description: "Table activated and created in database.",
      });
    } else {
      toast({
        title: "Error in Service",
        description: resp.usermessage,
      });
    }
    SetLoaded(true);
  };

  const Delete = async () => {
    debugger;
    SetLoaded(false);
    //validation starts
    if (routeParams.tabname) {
      const resp = await ViewService.delete(routeParams.tabname);
      if (resp) {
        navigate("/apps/ddic");
      } else {
        toast({
          title: "View cannot be deleted, it contains data",
        });
      }
    }

    SetLoaded(true);
  };

  return (
    <div className="p-4 bg-white h-screen">
      <h1 className="font-bold text-2xl text-red-800">Table Maintanence</h1>
      <div className="mb-4">
        <BreadcrumbPart />
      </div>
      <Separator className="my-4" />
      {/* Commands Start */}
      <div className="flex flex-row flex-nowrap my-2 gap-1 mb-10">
        <button
          className="w-8 h-8 bg-gray-100 flex justify-center items-center rounded-sm border-gray-300 border-[1px] hover:bg-gray-300"
          onClick={() => Delete()}
          title="Delete"
        >
          <Icon
            icon="material-symbols-light:delete-outline"
            width="18"
            height="18"
            className="text-black bg-white"
          />
        </button>
      </div>
      {/* Commands End */}
      {/* 
      <div className="flex flex-row flex-nowrap my-2">
        <Button className="" onClick={() => Save()}>
          Save
        </Button>
        <Button className="" onClick={() => Activate()}>
          Activate
        </Button>
      </div> */}

      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row items-center">
          <Label className="w-48">Table Name</Label>
          <Input
            className="bg-gray-100 w-40 uppercase"
            placeholder="?"
            value={PageObject.ViewName}
            maxLength={50}
            disabled={true}
            onChange={(e) => PropChanged("TabName", e.target.value)}
          />
        </div>
        <div className="flex flex-row items-center">
          <Label className="w-48">Short Description</Label>
          <Input
            className="bg-white w-96"
            placeholder="?"
            value={PageObject.Definition}
            maxLength={60}
            disabled={!canbeChanged}
            onChange={(e) => PropChanged("Definition", e.target.value)}
          ></Input>
        </div>

        {/* Tab Start */}
        <Tabs defaultValue="properties" className="md:w-2/3 mt-5">
          <TabsList>
            <TabsTrigger value="properties">Properties</TabsTrigger>
            <TabsTrigger value="tables">Tables</TabsTrigger>
            <TabsTrigger value="fields">Fields</TabsTrigger>
            <TabsTrigger value="fieldprops">Field Props</TabsTrigger>
          </TabsList>
          {/* Tab 1 : Properties */}
          <TabsContent value="properties" className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed By</Label>
              <Input
                className="bg-gray-100 w-64 lowercase"
                placeholder=""
                disabled={true}
                value={PageObject.LastChanged}
              ></Input>
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Changed On</Label>
              <Input
                className="bg-gray-100 w-24"
                placeholder=""
                disabled={true}
                value={PageObject.LastChangedOn}
              ></Input>
            </div>
            {/* <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Development Class</Label>
              <DevClass Value={PageObject.Package} Disabled={true}></DevClass>
            </div> */}
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Key Table</Label>
              <SearchHelp SHName="TABNAME" disabled={true} value={PageObject.KeyTable} />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Module</Label>
              <ModuleSelect Value={PageObject.ModuleCode} Disabled={true}></ModuleSelect>
            </div>
            <div className="flex flex-row items-center gap-2">
            <Label className="w-32">Out System</Label>
              <Checkbox
                checked={PageObject.Outsys}
                disabled={true}

              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Add Button</Label>
              <Checkbox
                checked={PageObject.Enable_Add}
                disabled={true}

              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Edit Button</Label>
              <Checkbox
                checked={PageObject.Enable_Mod}
                disabled={true}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Delete Button</Label>
              <Checkbox
                checked={PageObject.Enable_Del}
                disabled={true}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Detail Button</Label>
              <Checkbox
                checked={PageObject.Enable_Det}
                disabled={true}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Execute Button</Label>
              <Checkbox
                checked={PageObject.Enable_Exc}
                disabled={true}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Max. Row</Label>
              <Input
              className="w-24 bg-white text-right"
              title="Number of rows to display in the table. Default is 50."
                value={PageObject.MaxRow}
                type="number"
                disabled={true}
              />
            </div>
            <div className="flex flex-row items-center gap-2">
              <Label className="w-32">Edit Type</Label>
              <Select
                value={PageObject.EditType.toString()} // Convert EditType to string
                disabled={true}>
                                  <SelectTrigger className="w-[180px]" disabled={true}>
                  <SelectValue placeholder="Select data type" />
                </SelectTrigger>
               <SelectContent>
                  <SelectGroup>
                    <SelectItem value="0">Inline </SelectItem>
                    <SelectItem value="1">Modal </SelectItem>
                    <SelectItem value="2">Page </SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </TabsContent>
          {/* Tab 2 : Tables */}
          <TabsContent value="tables" className="flex flex-col gap-2 w-[130px]">
            <table>
              <thead>
                <tr className="font-unbold text-left">
                  <th>Related Table</th>
                  <th>Related Field</th>
                  <th>Fieldname</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Relations.map((rel, index) => (
                  <tr>
                    <td>
                      <Input
                        className="bg-gray-100 w-32"
                        placeholder=""
                        value={rel.RTabName}
                        disabled={true}

                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-gray-100 w-32"
                        placeholder=""
                        value={rel.RFieldName}
                        disabled={true}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-gray-100 w-32"
                        placeholder=""
                        value={rel.FieldName}
                        disabled={true}
                      ></Input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </TabsContent>
          {/* Tab 3 : Fields */}
          <TabsContent value="fields" className="flex flex-col gap-2">
            <table>
              <thead>
                <tr className="font-unbold text-left">
                  <th>Table Name</th>
                  <th>Field Name</th>
                  <th title="Overview?">O</th>
                  <th title="Detail?">D</th>
                  <th title="Enabled?">E</th>
                  <th title="Required?">R</th>
                  <th>Data Element</th>
                  <th>Domain</th>
                  <th>Data type</th>
                  <th>Length</th>
                  <th>Definition</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Columns.map((col, index) => (
                  <tr>
                    <td>
                      <Input
                        className="bg-white w-32"
                        placeholder=""
                        value={col.FieldName}
                        disabled={!canbeChanged}
                        onChange={(e) =>
                          FieldListChanged("FieldName", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.OScreen}
                        onCheckedChange={(checked) =>
                          FieldListChanged("OScreen", index, checked)
                        }
                      />
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.DScreen}
                        onCheckedChange={(checked) =>
                          FieldListChanged("DScreen", index, checked)
                        }
                      />
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.Enabled}
                        onCheckedChange={(checked) =>
                          FieldListChanged("Enabled", index, checked)
                        }
                      />
                    </td>
                    <td className="w-12">
                      <Checkbox
                        checked={col.Obligatory}
                        onCheckedChange={(checked) =>
                          FieldListChanged("Obligatory", index, checked)
                        }
                      />
                    </td>
                    <td>
                    <Input
                        className="bg-gray-100 w-28"
                        placeholder=""
                        value={col.Rollname}
                        disabled={false}
                        type="text"
                      ></Input>
                    </td>
                    <td>
                      {/* Domain */}
                      <Input
                        className="bg-gray-100 w-20"
                        placeholder=""
                        value={col.Domname}
                        disabled={false}
                      ></Input>
                    </td>
                    <td>
                      {/* Data Type */}
                      <Input
                        className="bg-gray-100 w-20"
                        placeholder=""
                        disabled={false}
                        value={col.Datatype}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-gray-100 w-16"
                        placeholder=""
                        disabled={false}
                        value={col.Length === 0 ? "" : col.Length}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-gray-100 w-64"
                        placeholder=""
                        disabled={false}
                        value={col.Definition}
                      ></Input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabsContent>
          {/* Tab 4 : Field Props */}
          <TabsContent value="fieldprops" className="flex flex-col gap-2">
            <table>
              <thead>
                <tr className="font-unbold text-left">
                  <th>Table Name</th>
                  <th>Field Name</th>
                  <th title="Sort?">So</th>
                  <th title="SortOrder?">Or</th>
                  <th title="Default?">Default?</th>
                  <th title="FieldType">FieldType</th>
                </tr>
              </thead>
              <tbody>
                {PageObject.Columns.map((col, index) => (
                  <tr>
                    <td>
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.TabName}
                        disabled={true}
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-white uppercase"
                        placeholder=""
                        value={col.FieldName}
                        disabled={true}
                      ></Input>
                    </td>
                    <td>
                      <SortType Value={col.SortType.toString()}  Disabled={false} OnSelect={(val) =>   FieldListChanged("SortType", index, val) } />
                    </td>
                    <td>
                      <Input
                        className="bg-white w-20"
                        placeholder=""
                        value={col.SortOrder}
                        disabled={false}
                        type="number"
                        onChange={(e) =>
                          FieldListChanged("SortOrder", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td>
                      <Input
                        className="bg-white w-28"
                        placeholder=""
                        value={col.DefaultValue}
                        disabled={false}
                        type="text"
                        onChange={(e) =>
                          FieldListChanged("DefaultValue", index, e.target.value)
                        }
                      ></Input>
                    </td>
                    <td>
                    <Input
                        className="bg-white lowercase"
                        placeholder=""
                        value={col.FieldType}
                        disabled={false}
                        onChange={(e) =>
                          FieldListChanged("FieldType", index, e.target.value)
                        }
                      ></Input>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TabsContent>
        </Tabs>
        <Toaster />
      </div>
    </div>
  );
}

export default DeleteViewDetail;
