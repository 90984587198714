import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer() {

  const { t, i18n } = useTranslation("", { keyPrefix: "Footer" });
  //
  return (<div className="py-32 relative bg-black">
    <img className="absolute bottom-0 right-0" src="consulty-assets/footer/shapes2.svg" alt="" />
    <div className="container mx-auto px-4">
      <div className="flex flex-wrap -m-4">
        <div className="w-full lg:w-1/3 p-4">
          <div className="flex flex-col gap-8 h-full">
            <div>
              <img className="h-11 mb-6 rounded-xl" src="logob.png" alt="" />
              <p className="tracking-tight text-gray-200 max-w-sm">
                {t("Word1")}

              </p>
            </div>

            <div className="inline-flex mt-4">
              <a
                href="https://www.linkedin.com/showcase/18293663/admin/feed/posts/"
                className="hover:opacity-75"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> LinkedIn </span>
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28" height="28" viewBox="0 0 48 48">
                  <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                </svg>
                {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                fill="#869AB8"
              />
            </svg> */}
              </a>
              <a
                href="https://www.instagram.com/orchestrahcm/"
                className="hover:opacity-75 ms-2"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> Instagram </span>

                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28" height="28" viewBox="0 0 64 64">
                  <linearGradient id="jm_nAfYbxsVmTlYr5N4x9a_43625_gr1" x1="32" x2="32" y1="6.667" y2="57.872" gradientUnits="userSpaceOnUse" spreadMethod="reflect"><stop offset="0" stop-color="#1a6dff"></stop><stop offset="1" stop-color="#c822ff"></stop></linearGradient><path fill="url(#jm_nAfYbxsVmTlYr5N4x9a_43625_gr1)" d="M44,57H20c-7.168,0-13-5.832-13-13V20c0-7.168,5.832-13,13-13h24c7.168,0,13,5.832,13,13v24 C57,51.168,51.168,57,44,57z M20,9C13.935,9,9,13.935,9,20v24c0,6.065,4.935,11,11,11h24c6.065,0,11-4.935,11-11V20 c0-6.065-4.935-11-11-11H20z"></path><linearGradient id="jm_nAfYbxsVmTlYr5N4x9b_43625_gr2" x1="32" x2="32" y1="18.167" y2="45.679" gradientUnits="userSpaceOnUse" spreadMethod="reflect"><stop offset="0" stop-color="#6dc7ff"></stop><stop offset="1" stop-color="#e6abff"></stop></linearGradient><path fill="url(#jm_nAfYbxsVmTlYr5N4x9b_43625_gr2)" d="M32,45c-7.168,0-13-5.832-13-13c0-7.168,5.832-13,13-13c7.168,0,13,5.832,13,13 C45,39.168,39.168,45,32,45z M32,23c-4.962,0-9,4.038-9,9c0,4.963,4.038,9,9,9c4.963,0,9-4.037,9-9C41,27.038,36.963,23,32,23z"></path><linearGradient id="jm_nAfYbxsVmTlYr5N4x9c_43625_gr3" x1="46" x2="46" y1="12.75" y2="23.049" gradientUnits="userSpaceOnUse" spreadMethod="reflect"><stop offset="0" stop-color="#6dc7ff"></stop><stop offset="1" stop-color="#e6abff"></stop></linearGradient><path fill="url(#jm_nAfYbxsVmTlYr5N4x9c_43625_gr3)" d="M46 15A3 3 0 1 0 46 21A3 3 0 1 0 46 15Z"></path>
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@orchestrahcm"
                className="hover:opacity-75 ms-2"
                target="_blank"
                rel="noreferrer"
              >
                <span className="sr-only"> YouTube </span>

                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28" height="28" viewBox="0 0 48 48">
                  <path fill="#FF3D00" d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z"></path><path fill="#FFF" d="M20 31L20 17 32 24z"></path>
                </svg>
              </a>





            </div>
            {/* <input type="text" className="flex-1 placeholder-gray-500 bg-transparent outline-none text-white" placeholder="Enter email address" />
            <a href="#" className="text-orange-400 hover:text-orange-500 transition duration-200">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
                <path d="M18.75 6.1875V15.9375C18.75 16.1364 18.671 16.3272 18.5303 16.4678C18.3897 16.6085 18.1989 16.6875 18 16.6875C17.8011 16.6875 17.6103 16.6085 17.4697 16.4678C17.329 16.3272 17.25 16.1364 17.25 15.9375V7.99781L6.53062 18.7181C6.38988 18.8589 6.19901 18.9379 5.99999 18.9379C5.80097 18.9379 5.6101 18.8589 5.46936 18.7181C5.32863 18.5774 5.24957 18.3865 5.24957 18.1875C5.24957 17.9885 5.32863 17.7976 5.46936 17.6569L16.1897 6.9375H8.24999C8.05108 6.9375 7.86031 6.85848 7.71966 6.71783C7.57901 6.57718 7.49999 6.38641 7.49999 6.1875C7.49999 5.98859 7.57901 5.79782 7.71966 5.65717C7.86031 5.51652 8.05108 5.4375 8.24999 5.4375H18C18.1989 5.4375 18.3897 5.51652 18.5303 5.65717C18.671 5.79782 18.75 5.98859 18.75 6.1875Z" fill="currentColor"></path>
              </svg>
            </a> */}

          </div>
        </div>
        <div className="w-full lg:w-2/3 p-4">
          <div className="flex flex-wrap justify-between gap-12">
            <div>
              <p className="tracking-tight text-gray-100 font-medium mb-6">{t("Product")}</p>
              <ul className="flex flex-col gap-2">
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("OrganizationManagement")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("PersonnelAdministration")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("NegativeTimeManagement")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("OrganizationVisualizer")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("OnBoarding")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Recruitment")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("PositiveTimeManagement")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Payroll")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("PerformanceManagement")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Survey")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("TravelManagement")}</li>
                <li className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("CompensationManagement")}</li>
                {/* <li><a href="#" className="tracking-tight text-white hover:text-orange-500 transition duration-200">Small Business</a></li> */}
              </ul>
            </div>
            <div>
              <p className="tracking-tight text-gray-100 font-medium mb-6">{t("Company")}</p>
              <ul className="flex flex-col gap-2">
                <li><a href="https://hrsp.com.tr" target="_blank" className="tracking-tight text-white hover:text-orange-500 transition duration-200">HRSP</a></li>
                <li><a href="https://hrsp.orchestra-bu.com" target="_blank" className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Support")}</a></li>
              </ul>
            </div>
            <div>
              <p className="tracking-tight text-gray-500 font-medium mb-6">{t("Getintouch")}</p>
              <ul className="flex flex-col gap-2">
                <li><a href="#" className="tracking-tight text-white hover:text-orange-500 transition duration-200">+90 555 555 4 777</a></li>
                <li>
                  <a href="mailto:info@hrsp.com.tr" className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Contact")}</a>

                  {/* <Link to="/contact" className="tracking-tight text-white hover:text-orange-500 transition duration-200">{t("Contact")}</Link> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Footer;
