import { EditType } from "./Enums";
import { OrcViewColumn } from "./OrcViewColumn";

export class  OrcViewResult {
  ViewName: string = "";
  Definition: string = "";
  // SubTitle?: string;
  Data?: any;
  Columns: OrcViewColumn[] = [];
  //
  Enable_Add: boolean = true;
  Enable_Mod: boolean = true;
  Enable_Del: boolean = true;
  Enable_Exc: boolean = true;
  Enable_Copy: boolean = true;
  Enable_Det: boolean = true;
  OpenSourceUpdate: boolean = false;
  //
  EditType: EditType = EditType.Inline;
  HasError: boolean = false;
  ErrorMessage?: string;
}
