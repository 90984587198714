import DIContainer from "@/models/DIContainer";
import IResponseHelper, { ResponseIsNameOk } from "@/models/response/IResponseHelper";
import { Domain } from "@/models/datadictionary/Domain";

class ObjNameService {
  private httpClient = DIContainer.HttpJsonClient;


  IsNameOk = async (domname: string) => {
    const response = await this.httpClient.get<IResponseHelper<ResponseIsNameOk>>(
      "/api/v1/dd/objname/IsNameOk?Name=" + domname
    );

    return response.data;
  };


}

export default new ObjNameService();
