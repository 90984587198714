import { DomainHelper } from "@/lib/DomainHelper";
import { Button } from "@/shadcn/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/shadcn/ui/card";
import { Input } from "@/shadcn/ui/input";
import { Label } from "@/shadcn/ui/label";
import { useNavigate } from "react-router-dom";

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthApiService from "@/services/auth/AuthApiService";
import DIContainer from "@/models/DIContainer";
import { AuthToken } from "@/services/auth/AuthToken";
import BaseError from "@/models/errors/BaseError";

import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "@/shadcn/ui/use-toast";
import { Toaster } from "@/shadcn/ui/toaster";
import { ReloadIcon } from "@radix-ui/react-icons";
import { OrcContext } from "@/context/OrcContext";
import LanguSelect from "@/pages/protected/comps/LanguSelect";
import ClientExistCheckService from "@/services/clientexist/ClientExistCheckService";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/shadcn/ui/select";
import QRCode from "react-qr-code";
import useHttpClient from "@/hook/useHttpClient";
import IResponseHelper from "@/models/response/IResponseHelper";

const LoginSchema = z.object({
  companyid: z.string().min(6).max(6).default("123456"),
  username: z.string().min(4).max(100).default("admin@ides.com"),
  password: z.string().min(4).max(40).default("Deneme12!"),
  // langu: z.string().min(2).max(2),
});
type LoginSchemaType = z.infer<typeof LoginSchema>;




function Login() {
  //
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchemaType>({ resolver: zodResolver(LoginSchema) });
  //
  const { t, i18n } = useTranslation("", { keyPrefix: "Login" });
  const [loading, setLoading] = useState(false);
  const [clientfound, setClientFound] = useState(false);
  const [bgImage, setBgImage] = useState("https://images.unsplash.com/photo-1721626782208-ecfdc6625fc0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const { isLoading, request, login } = useHttpClient();

  const currentContext = useContext(OrcContext);

  useEffect(() => {
    setLoading(true);
    fetch();
    setLoading(false);
   
   }, []);
   
   const fetch = async () => {
    let subdomain = DomainHelper.SubDomain;
    if (subdomain == "") {
      toast({
        title: "Client not found",
        description: "Please check your client id and try again.",
      });
      return;
    }

  
    const resp = await request<IResponseHelper<boolean>>(`/api/v1/clientcheck?subdomain=${subdomain}`, "GET");
    if(resp==null) return;

    if (resp.data == false) {
      //alet("error");
      toast({
        title: "Client not found",
        description: "Please check your client id and try again.",
      });
    }
    else
    {
      setClientFound(true);
    }   
     
   };


   const onSubmit: SubmitHandler<LoginSchemaType> = async (data) => {

    debugger;
    const resp = await login({
      companyid: data.companyid,
      username: data.username,
      password: data.password,
    });
    if(resp==null) return;
    //
    if(resp.status == 200)
    { 
      currentContext.saveUser({
        Pernr: "",
        UserName: data.username,
        Ename: data.username,
        Title: "",
      });
      //currentContext?.updateTheme("DarkBlue");
      navigate("/apps/exec/home");
    }
    else
    {
      toast({
        title: "Wrong credentials",
        description: "Please check user credentials and try again.",});

    }
     // navigate("/apps/exec/home");

  };




  const onSubmitOrg: SubmitHandler<LoginSchemaType> = async (data) => {
    try {
      debugger;
      console.log(data);
      setLoading(true);
      // DIContainer.HttpClient.addHeader("MY_LANGU", i18n.language.toUpperCase());

      const loginResponse = await AuthApiService.login(
        data.username,
        data.password,
        data.companyid
      );

      if(loginResponse.Token == null)
      {
        setLoading(false);
        toast({
          title: loginResponse.Message ? loginResponse.Message : "Error",
          description: loginResponse.ErrorDetail ? loginResponse.ErrorDetail : "Error",
        });
        return;
      }

      const authToken = AuthToken.fromLoginResponse(loginResponse);

      if (!authToken) {
        setLoading(false);
        throw new BaseError("Couldn't get auth token from login response");
      } else {
        debugger;
        currentContext.saveUser({
          Pernr: "",
          UserName: data.username,
          Ename: data.username,
          Title: "",
        });
        //currentContext?.updateTheme("DarkBlue");
        navigate("/apps/exec/home");
      }
    } catch {
      setLoading(false);
      //alert("error");
      toast({
        title: "Wrong credentials",
        description: "Please check user credentials and try again.",
      });
    }
  };

  const navigate = useNavigate();

  // const [username, SetUserName] = useState("P1004");
  // const [password, SetPassword] = useState("Deneme12!!");

  // const [username, SetUserName] = useState("");
  // const [password, SetPassword] = useState("");

  // const SignIn = async () => {
  //   try {
  //     DIContainer.HttpClient.addHeader("MY_LANGU", "TR");

  //     // Login user
  //     const loginResponse = await AuthApiService.login(username, password);

  //     const authToken = AuthToken.fromLoginResponse(loginResponse);

  //     if (!authToken) {
  //       throw new BaseError("Couldn't get auth token from login response");
  //     } else {
  //       navigate("/apps/home");
  //     }
  //   } catch {
  //     alert("hata oldu");
  //   }
  // };

  return clientfound ? (
  
    <section className="w-screen min-h-svh bg-cover flex items-center justify-center" style={{ backgroundImage : `url(${bgImage})` }}>
      {/* <img src="/logob.png" alt="OrchestraHR" className="fixed mt-4 lt-4 h-24 w-24 rounded-lg" /> */}
      <div className="mx-auto flex px-5 py-12 items-center justify-center flex-col">
        <Card className="w-[350px] bg-red-200 bg-opacity-60 text-black">
          <CardHeader>
            <div className="w-full flex flex-col items-center justify-center gap-2">
              <div className="w-full flex justify-between">
                <img src="/logob.png" alt="OrchestraHR" className="h-12 w-12 rounded-lg" /> 
                <img src="https://hrsp.com.tr/assets/img/HRSPLogo400.png" alt="OrchestraHR" className="h-12 w-12 rounded-full" />
              </div>
              <QRCode   style={{ height: "auto", maxWidth: "40%", width: "40%" }} value={window.location.origin} />
            </div>
        
            {/* <CardTitle className="font-semibold text-2xl text-center">
            

              {t("ManageYourHR")}
            </CardTitle> */}
            {/* <CardDescription>Enter your user credentials.</CardDescription> */}
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid w-full items-center gap-3">
                {/* <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">{t("CompanyID")}</Label>
                  <Input
                    placeholder={t("Entercompanyid")}
                    autoComplete="new-password"
                    type="password"
                    defaultValue={
                      process.env.NODE_ENV === "development" ? "123456" : ""
                    }
                    maxLength={6}
                    {...register("companyid")}
                    className={`bg-white ${
                      errors.companyid ? "bg-red-50" : "bg-white"
                    }`}
                  />
                </div> */}
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">{t("Username")}</Label>
                  <Input
                    placeholder={t("Enterusername")}
                    autoComplete="new-password"
                    {...register("username")}
                    defaultValue={
                      process.env.NODE_ENV === "development"
                        ? "bekirk@hrsp.com.tr"
                        : ""
                    }
                    className={`bg-white ${
                      errors.username ? "bg-red-50" : "bg-white"
                    }`}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label>{t("Password")}</Label>
                  <Input
                    // id="password"
                    type="password"
                    placeholder={t("Enterpassword")}
                    autoComplete="new-password"
                    {...register("password")}
                    defaultValue={
                      process.env.NODE_ENV === "development" ? "Bekir1973!" : ""
                    }
                    className={`bg-white ${
                      errors.password ? "bg-red-50" : "bg-white"
                    }`}
                  />
                  <LanguSelect Value={i18n.language.toUpperCase()} OnSelect={(e) => { i18n.changeLanguage(e.toLowerCase()); localStorage.setItem('langu', e.toLowerCase());}} />

                </div>
                {/* <div className="flex flex-col space-y-1.5">
                  <Label>{t("Language")}</Label>
                  <Select {...register("langu")} defaultValue={i18n.language}>
                    <SelectTrigger className="w-[120px] bg-white">
                      <SelectValue></SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value="tr">Türkçe</SelectItem>
                        <SelectItem value="en">English</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div> */}
                <div className="flex flex-col space-y-1.5">
                  <Button
                    // variant="outline"
                    className="bg-gray-800 hover:bg-gray-600 text-white"
                    disabled={loading}
                    //onClick={SignIn}
                    type="submit"
                  >
                    {loading && (
                      <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    {t("SignIn")}
                  </Button>
                </div>
              </div>
            </form>
          </CardContent>
          {/* <CardFooter className="flex justify-between">
            <Button
              variant="outline"
              className="bg-gray-50 hover:bg-gray-200"
              onClick={SignIn}
            >
              Sign-In
            </Button>
            <Button>Forget Pasword</Button>
          </CardFooter> */}
        </Card>
        <Toaster />
      </div>
    </section>) : (<div>Client Id { DomainHelper.SubDomain} not found</div>);}

  


export default Login;
