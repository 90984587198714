import DIContainer from "@/models/DIContainer";
import { Program } from "@/models/development/Program";
import IResponseHelper from "@/models/response/IResponseHelper";

class ProgService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: Program) => {
    const response = await this.httpClient.post<IResponseHelper<Program>>(
      "/api/v1/dev/prog",
      data
    );

    return response;
  };

  compile = async (data: Program) => {
    const response = await this.httpClient.post<IResponseHelper<Program>>(
      "/api/v1/dev/prog/compile",
      data
    );

    return response;
  };

  put = async (data: Program) => {
    const response = await this.httpClient.put<IResponseHelper<Program>>(
      "/api/v1/dev/prog",
      data
    );

    return response;
  };

  delete = async (progname: string) => {
    const response = await this.httpClient.delete<IResponseHelper<Program>>(
      "/api/v1/dev/prog?progname=" + progname
    );

    return response;
  };

  
  copy = async (copyprogname: string, progname: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/dev/prog/copy?copyprogname=" + copyprogname + "&progname=" + progname
    );

    return response.data;
  };

  isExist = async (progname: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/dev/prog/isexist?progname=" + progname
    );

    return response.data;
  };

  getByName = async (progname: string) => {
    const response = await this.httpClient.get<IResponseHelper<Program>>(
      "/api/v1/dev/prog/getByName?progname=" + progname
    );

    return response.data;
  };
}

export default new ProgService();
