import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { SideNavItem } from "@/models/Types";
import { SIDENAV_ITEMS } from "@/constants";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { OrcContext } from "@/context/OrcContext";
import { Button } from "@/shadcn/ui/button";
import useScroll from "@/hook/useScroll";
import LeftMenuService from "@/services/nav/LeftMenuService";
import useHttpClient from "@/hook/useHttpClient";
import IResponseHelper from "@/models/response/IResponseHelper";
import { OrcScreen } from "@/models/screen/OrcScreen";


const SideNav = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<SideNavItem[]>([]);
  const [expandedMode, setSExpanded] = useState(true);
  const scrolled = useScroll(5);
  // let SideBarBGColor = "bg-blue-900";
  // let SideBarBGColor = "bg-black";
  // let SideBarTextColor = "text-white";
  let SideBarWidthExpanded = "md:w-56";

  let ShowExpandButton: boolean = true;

  const { isLoading, request } = useHttpClient();

  // SideBarBGColor = "bg-gray-700";
  // SideBarTextColor = "text-white";
  SideBarWidthExpanded = "md:w-56";
  const navigate = useNavigate();
  // SideBarBGColor = "bg-primary/70";

  if (expandedMode) SideBarWidthExpanded = "md:w-40";
  else SideBarWidthExpanded = "md:w-12";

  const currentContext = useContext(OrcContext);

  const Expand = () => {
    let newExpanded = !expandedMode;
    setSExpanded(newExpanded);

    currentContext?.saveUser({
      Pernr: "2",
      UserName: "serenk",
      Ename: "Seren Karadeniz",
      Title: "Genel Müdür",
    });
  };

  useEffect(() => {
    setLoaded(false);
    fetch();
    setLoaded(true);
  }, []);

  const fetchOld = async () => {
    const resp = await LeftMenuService.get();
    if (resp.data) {
      setData(resp.data);
    } else {
      //toast.success(resp.Error); // Displays a success message
      debugger;
      alert(resp.error);
    }
    debugger;
  };

  const fetch = async () => {
    //
    //const resp = await request<IResponseHelper<OrcScreen>>(`/api/v1/screen/execute`, "POST", screen);

    const resp = await request<IResponseHelper<SideNavItem[]>>(`/api/v1/nav/leftmenu`, "GET");
    if(resp==null) return;
    //
    //const resp = await LeftMenuService.get();
    if (resp.data) {
      setData(resp.data);
    } else {
      //toast.success(resp.Error); // Displays a success message
      debugger;
      alert(resp.error);
    }
    debugger;
  };

  const GotoPage = (lnk: string) => {
    if(lnk.startsWith("http")) {
      window.open(lnk, "_blank");
      return;
    }
    if (lnk == "/logout") {
      currentContext.removeUser();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else navigate(lnk);
    //alert(lnk);
  };

  return (
    <div
      className={`hidden md:flex  md:min-h-screen bg-gray-800 text-white ease-in-out duration-500 ${
        expandedMode ? "md:w-56" : "md:w-12"
      }`}
    >
      <div className="flex flex-col w-full">
        <div className="w-full h-10 flex items-center justify-end px-2 pt-5">
          <div
            onClick={Expand}
            className="text-white h-6 w-6 sm:h-6 sm:w-6 hover:cursor-pointer"
          >
            <Bars3Icon />
          </div>
        </div>

        {/* Company Logo */}
        {expandedMode && (
          <Link
            to="/"
            className="flex flex-row items-center justify-center mt-5 h-12 w-full"
          >
              <img src="/logoc.png" className="rounded-full" width={70} />

          </Link>
        )}

        <div className="flex flex-col mt-8">
          {loaded &&
            data.map((item, idx) => {
              return (
                <Button
                key={item.menucode}
                  accessKey={item.path}
                  onClick={() => GotoPage(item.path)}
                  className="w-full flex flex-row space-x-3 h-8 items-center text-white justify-start rounded-none p-3 bg-transparent hover:bg-primary/40 hover:cursor-pointer"
                >
                  <Icon icon={item.iconname ? item.iconname : "icon-park:check-one"} width={22} height={22} />

                  {expandedMode && (
                    <span className="flex text-white font-extralight">
                      <a href={item.path} onClick={(e) => e.preventDefault()}>
                        {item.title}</a>
                    </span>
                  )}
                </Button>
              );
            })}
          {/* {expandedMode == true &&
            SIDENAV_ITEMS.map((item, idx) => {
              return <MenuItemExpanded key={idx} item={item} />;
            })}
          {expandedMode == false &&
            SIDENAV_ITEMS.map((item, idx) => {
              return <MenuItemNotExpanded key={idx} item={item} />;
            })} */}
        </div>
      </div>
    </div>
  );
};

const MenuItemExpanded3 = ({
  item,
  expanded,
}: {
  item: SideNavItem;
  expanded: boolean;
}) => {
  // SideBarBGBtnHover = "hover:bg-blue-500";
  // const pathname = usePathname();
  const pathname = "usePathname()";
  const currentContext = useContext(OrcContext);
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const GotoPage = (lnk: string) => {
    if (lnk == "/logout") {
      currentContext.removeUser();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else navigate(lnk);
    //alert(lnk);
  };

  return (
    <div className="text-sm">
      {item.submenu ? (
        <>
          <button
            onClick={toggleSubMenu}
            className={`flex flex-row items-center px-3 py-2 rounded-lg hover-bg-zinc-100 w-full justify-between hover:bg-blue-500 ${
              pathname.includes(item.path) ? "bg-zinc-100" : ""
            }`}
          >
            <div className="flex flex-row space-x-3 items-center">
              {item.icon}

              {expanded && <span className="flex">{item.title}</span>}
            </div>

            <div
              className={`${
                subMenuOpen
                  ? "rotate-180 transform duration-150"
                  : "transform duration-150"
              } flex`}
            >
              <Icon icon="lucide:chevron-down" width="24" height="24" />
            </div>
          </button>

          {subMenuOpen && (
            <div className="my-1 ml-10 flex flex-col space-y-1 rounded-lg">
              {item.subMenuItems?.map((subItem, idx) => {
                return (
                  <Link
                    key={idx}
                    to={subItem.path}
                    className={`hover:bg-blue-400 p-1 ${
                      subItem.path === pathname ? "font-bold" : ""
                    }`}
                  >
                    <span>{subItem.title}</span>
                  </Link>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <Button
          onClick={() => GotoPage(item.path)}
          className="w-full flex flex-row space-x-3 items-center text-foreground justify-start rounded-none p-3 bg-transparent hover:bg-primary/40"
          //className="bg-primary hover:bg-primary/90 z-50"

          // className={`flex flex-row space-x-3 items-center justify-start rounded-none p-3 bg-primary shadow hover:bg-opacity-70 w-full ${
          //   item.path === pathname ? "bg-gray-700" : ""
          // }`}
        >
          {item.icon}
          <span className="flex text-foreground">{item.title}</span>
        </Button>
      )}
    </div>
  );
};

const MenuItemExpanded = ({ item }: { item: SideNavItem }) => {
  // SideBarBGBtnHover = "hover:bg-blue-500";
  // const pathname = usePathname();
  const pathname = "usePathname()";
  const currentContext = useContext(OrcContext);
  const navigate = useNavigate();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const GotoPage = (lnk: string) => {
    if (lnk == "/logout") {
      currentContext.removeUser();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else navigate(lnk);
    //alert(lnk);
  };

  return (
    <div className="text-sm">
      {item.submenu ? (
        <>
          <button
            onClick={toggleSubMenu}
            className={`flex flex-row items-center px-3 py-2 rounded-lg hover-bg-zinc-100 w-full justify-between hover:bg-blue-500 ${
              pathname.includes(item.path) ? "bg-zinc-100" : ""
            }`}
          >
            <div className="flex flex-row space-x-3 items-center">
              {item.icon}

              <span className="flex">{item.title}</span>
            </div>

            <div
              className={`${
                subMenuOpen
                  ? "rotate-180 transform duration-150"
                  : "transform duration-150"
              } flex`}
            >
              <Icon icon="lucide:chevron-down" width="24" height="24" />
            </div>
          </button>

          {subMenuOpen && (
            <div className="my-1 ml-10 flex flex-col space-y-1 rounded-lg">
              {item.subMenuItems?.map((subItem, idx) => {
                return (
                  <Link
                    key={idx}
                    to={subItem.path}
                    className={`hover:bg-blue-400 p-1 ${
                      subItem.path === pathname ? "font-bold" : ""
                    }`}
                  >
                    <span>{subItem.title}</span>
                  </Link>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <Button
          onClick={() => GotoPage(item.path)}
          className="w-full flex flex-row space-x-3 items-center text-foreground justify-start rounded-none p-3 bg-transparent hover:bg-primary/40"
          //className="bg-primary hover:bg-primary/90 z-50"

          // className={`flex flex-row space-x-3 items-center justify-start rounded-none p-3 bg-primary shadow hover:bg-opacity-70 w-full ${
          //   item.path === pathname ? "bg-gray-700" : ""
          // }`}
        >
          {item.icon}
          <span className="flex text-foreground">{item.title}</span>
        </Button>
      )}
    </div>
  );
};

const MenuItemNotExpanded = ({ item }: { item: SideNavItem }) => {
  const navigate = useNavigate();

  // let SideBarBGBtnHover = "hover:bg-blue-500";
  // const pathname = usePathname();
  const pathname = "usePathname()";
  // const [subMenuOpen, setSubMenuOpen] = useState(false);

  const gotoPath = (path: string | undefined) => {
    if (path) navigate(path);
  };

  return (
    <div className="text-sm">
      <button
        onClick={() => gotoPath(item.path)}
        className={`relative group flex flex-row items-center px-3 py-2 rounded-lg hover-bg-zinc-100 w-full justify-between hover:bg-blue-500 ${
          pathname.includes(item.path) ? "bg-zinc-100" : ""
        }`}
      >
        <div className="flex flex-row space-x-3 items-center">{item.icon}</div>
        <div className="absolute left-12 top-0 hidden group-hover:block  w-48 bg-blue-800 p-2 bg-opacity-65 rounded-md">
          {item.submenu &&
            item.subMenuItems?.map((subItem, idx) => {
              return (
                <Link key={idx} to={subItem.path}>
                  <div className="text-white text-left h-6 left-2 hover:underline">
                    {subItem.title}xx
                  </div>
                </Link>
              );
            })}

          {(item.submenu == null || item.submenu == false) && (
            <Link key={"sdds"} to={item.path}>
              <div className="text-white text-left hover:underline">
                {item.title}
              </div>
            </Link>
          )}
        </div>
      </button>
    </div>
  );
};

const MenuItemNotExpandedBackup = ({ item }: { item: SideNavItem }) => {
  let SideBarBGBtnHover = "hover:bg-blue-500";
  // const pathname = usePathname();
  const pathname = "usePathname()";
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const toggleSubMenu = () => {
    //setSubMenuOpen(!subMenuOpen);
  };

  return (
    <div className="text-sm">
      {item.submenu ? (
        <>
          <button
            onClick={toggleSubMenu}
            className={`flex flex-row items-center px-3 py-2 rounded-lg hover-bg-zinc-100 w-full justify-between hover:bg-blue-500 ${
              pathname.includes(item.path) ? "bg-zinc-100" : ""
            }`}
          >
            <div className="flex flex-row space-x-3 items-center">
              {item.icon}

              {/* <span className="flex">{item.title}</span> */}
            </div>

            <div
              className={`${
                subMenuOpen
                  ? "rotate-180 transform duration-150"
                  : "transform duration-150"
              } flex`}
            >
              {/* <Icon icon="lucide:chevron-down" width="24" height="24" /> */}
            </div>
          </button>

          {subMenuOpen && (
            <div className="my-1 ml-10 flex flex-col space-y-1 rounded-lg">
              {item.subMenuItems?.map((subItem, idx) => {
                return (
                  <Link
                    key={idx}
                    to={subItem.path}
                    className={`hover:bg-blue-400 p-1 ${
                      subItem.path === pathname ? "font-bold" : ""
                    }`}
                  >
                    {/* <span>{subItem.title}</span> */}
                  </Link>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <Link
          to={item.path}
          className={`flex flex-row space-x-3 items-center p-3 rounded-lg hover:bg-blue-500 ${
            item.path === pathname ? "bg-blue-400" : ""
          }`}
        >
          {item.icon}
          {/* <span className="flex">{item.title}</span> */}
          {/* <span className="flex">Employee Self Services</span> */}
        </Link>
      )}
    </div>
  );
};

export default SideNav;
