import Item from "@/models/screen/Item";
import { CardRowItem } from "@/models/Types";
import { Button } from "@/shadcn/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/shadcn/ui/card";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/shadcn/ui/dropdown-menu";
import { Separator } from "@/shadcn/ui/separator";
import clsx from "clsx";

export interface IOrcCardTable {
  FieldName: string;
  ClassName?: string;
  ParentFieldName?: string;
  Title: string;
  SubTitle?: string;
  Rows?: CardRowItem[];

}

export default function OrcCardTable(props: IOrcCardTable) {




  return (
    <>
        <Card>
            <CardHeader className={clsx("flex flex-row items-start bg-muted/50 p-2", props.ClassName)} >
              <div className="grid gap-0.5">
                <CardTitle className="group flex items-center gap-2 text-lg">
                {props.Title}
                </CardTitle>
                <CardDescription>{ props.SubTitle}</CardDescription>
              </div>
            </CardHeader>
              <CardContent className="p-6 text-sm">
                <div className="grid gap-3">
                  <ul className="grid gap-3">
                    {props.Rows?.map((item, index) => 
                          <li className="flex items-center justify-between">
                          <span className="text-muted-foreground font-semibold">
                          {item.Caption}
                          </span>
                          <span> {item.Data}</span>
                        </li>)}
                  </ul>
                </div>
              </CardContent>
              {/* <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
                <div className="text-xs text-muted-foreground">
                  Updated <time dateTime="2023-11-23">November 23, 2023</time>
                </div>

              </CardFooter> */}
            </Card>
    </>
  );
}
