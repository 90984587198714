type VariantType = "default" | "destructive" | "info" | "success" | "warning";

export enum ReturnType {
  Success = 1,
  Info = 2,
  Warning = 3,
  Error = 4,
  Default = 5,
}

const returnTypeToVariantMap: Record<ReturnType, string> = {
  [ReturnType.Error]: "destructive",
  [ReturnType.Success]: "success",
  [ReturnType.Info]: "info",
  [ReturnType.Warning]: "warning",
  [ReturnType.Default]: "default",
};

export const getVariantFromType = (
  type: ReturnType | undefined
): VariantType => {
  if (type && type in returnTypeToVariantMap) {
    return returnTypeToVariantMap[type] as VariantType;
  }
  return "default";
};
