import DIContainer from "@/models/DIContainer";
import IResponseHelper from "@/models/response/IResponseHelper";
import { Domain } from "@/models/datadictionary/Domain";
import { User } from "@/models/idm/User";
import { UIPageModel } from "@/models/uipage/UIPageModel";

class UIPageService {
  private httpClient = DIContainer.HttpJsonClient;

  post = async (data: UIPageModel) => {
    const response = await this.httpClient.post<IResponseHelper<UIPageModel>>(
      "/api/v1/ui/page",
      data
    );

    return response;
  };


  put = async (data: UIPageModel) => {
    const response = await this.httpClient.put<IResponseHelper<UIPageModel>>(
      "/api/v1/ui/page",
      data
    );

    return response;
  };

  isExist = async (pagename: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/ui/page/isexist?pagename=" + pagename
    );

    return response.data;
  };

  copy = async (copypagename: string, pagename: string) => {
    const response = await this.httpClient.get<IResponseHelper<boolean>>(
      "/api/v1/ui/page/copy?copypagename=" + copypagename + "&pagename=" + pagename
    );

    return response.data;
  };

  getByName = async (pagename: string) => {
    const response = await this.httpClient.get<IResponseHelper<UIPageModel>>(
      "/api/v1/ui/page/getByName?pagename=" + pagename
    );

    return response.data;
  };

  delete = async (pagename: string) => {
    const response = await this.httpClient.delete<IResponseHelper<Boolean>>(
      "/api/v1/ui/page/delete?pagename=" + pagename
    );

    return response.data;
  };
}

export default new UIPageService();
